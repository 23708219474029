import React from 'react'
import { IconButton, Theme } from '@mui/material'
import { Calendar, Field, PuiTextField } from '@pbt/pbt-ui-components'
import { Delete as DeleteIcon } from '@pbt/pbt-ui-components/src/icons'

import Typography from '../Typography/Typography'
import { PreviousMedicalHistoryItem } from './PreviousMedicalHistoryItem'

export const DiagnosisHistoryItemColumnWidths = [
  '25%',
  '20%',
  '5%',
  '25%',
  '20%',
  '5%',
]

export interface DiagnosisHistoryItemProps {
  backgroundColor?: keyof Theme['colors']
  date: Field
  doctor: Field
  name: string
  notes: Field
  onDelete: () => void
  practice: Field
}

export const DiagnosisHistoryItem = ({
  name,
  date,
  practice,
  doctor,
  notes,
  backgroundColor,
  onDelete,
}: DiagnosisHistoryItemProps) => (
  <PreviousMedicalHistoryItem
    backgroundColor={backgroundColor}
    fields={[
      {
        name: 'Diagnosis',
        field: <Typography.H3>{name}</Typography.H3>,
        width: DiagnosisHistoryItemColumnWidths[0],
      },
      {
        name: 'Date',
        field: <Calendar fullWidth field={date} label={date.label} />,
        width: DiagnosisHistoryItemColumnWidths[1],
      },
      {
        name: 'Spacer',
        field: '',
        width: DiagnosisHistoryItemColumnWidths[2],
      },
      {
        name: 'Practice',
        field: <PuiTextField field={practice} />,
        width: DiagnosisHistoryItemColumnWidths[3],
      },
      {
        name: 'Doctor',
        field: <PuiTextField field={doctor} />,
        width: DiagnosisHistoryItemColumnWidths[4],
      },
      {
        name: 'Actions',
        field: (
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        ),
        width: DiagnosisHistoryItemColumnWidths[5],
      },
    ]}
    notes={notes}
  />
)
