import React from 'react'
import { Box, BoxProps, CircularProgress, Grid } from '@mui/material'
import { palette, PaletteColor } from '@pbt/pbt-ui-components'
import {
  Check,
  Info,
  WarnAlert,
  Warning,
} from '@pbt/pbt-ui-components/src/icons'

import Typography from '../Typography/Typography'

export type AlertVariant = 'info' | 'good' | 'warning' | 'error' | 'loading'

export interface AlertBannerProps {
  ContainerProps?: BoxProps
  actions?: React.ReactNode | { linkText: string; onClick: () => void }
  content?: React.ReactNode
  contentMargin?: number
  fullWidth?: boolean
  title?: React.ReactNode
  variant: AlertVariant
}

const getCurrentVariant = (variant: AlertVariant) => {
  const IconMap: Record<
    AlertVariant,
    { Icon: React.ReactElement; bgColor: string; color: PaletteColor }
  > = {
    info: {
      Icon: <Info color="info" />,
      color: 'brandPrimary',
      bgColor: palette.shadesNormal,
    },
    good: {
      Icon: <Check sx={{ color: (theme) => theme.colors.shadesDarkSuccess }} />,
      color: 'uiSuccess',
      bgColor: palette.shadesSuccess,
    },
    loading: {
      Icon: (
        <Grid container width="auto">
          <Grid item>
            <CircularProgress size={16} sx={{ mr: '5px' }} />
          </Grid>
        </Grid>
      ),
      color: 'brandPrimary',
      bgColor: palette.shadesNormal,
    },
    warning: {
      Icon: (
        <Warning color="warning" sx={{ px: '5px', pt: '4px', pb: '6px' }} />
      ),
      color: 'uiNeedsAttention',
      bgColor: palette.shadesNeedsAttention,
    },
    error: {
      Icon: (
        <WarnAlert color="error" sx={{ px: '5px', pt: '4px', pb: '6px' }} />
      ),
      color: 'uiError',
      bgColor: palette.shadesError,
    },
  }

  return IconMap[variant]
}

export const AlertBanner = ({
  ContainerProps,
  actions,
  content,
  title,
  variant,
  contentMargin,
  fullWidth,
}: AlertBannerProps) => {
  const { bgColor, color, Icon } = getCurrentVariant(variant)
  return (
    <Box
      bgcolor={bgColor}
      border={`1px solid ${palette[color]}`}
      borderRadius={0.5}
      display="flex"
      minWidth={366}
      p={1}
      width={fullWidth ? 'auto' : 'fit-content'}
      {...ContainerProps}
    >
      <Box display="flex">
        {Icon}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          ml={0.5}
          mr={contentMargin}
        >
          {title && (
            <>
              {React.isValidElement(title) ? (
                title
              ) : (
                <Typography.H3 color={color}>{title}</Typography.H3>
              )}
            </>
          )}
          {content && (
            <>
              {React.isValidElement(content) ? (
                content
              ) : (
                <Typography.Paragraph color={color}>
                  {content}
                </Typography.Paragraph>
              )}
            </>
          )}
        </Box>
      </Box>
      {actions && (
        <Box ml="auto" mt="auto">
          {typeof actions === 'object' &&
          'onClick' in actions &&
          'linkText' in actions ? (
            <Typography.Link color={color} onClick={actions.onClick}>
              {actions.linkText}
            </Typography.Link>
          ) : (
            actions
          )}
        </Box>
      )}
    </Box>
  )
}
