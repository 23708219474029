import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Theme, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { curry } from 'ramda'
import {
  Defaults,
  PrimitiveTableColumn,
  Text,
  useInterval,
  WellnessPlans,
} from '@pbt/pbt-ui-components'

import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import FeatureToggle from '~/constants/featureToggle'
import i18n from '~/locales/i18n'
import { getHasOpenDialogs } from '~/store/duck/dialogs'
import {
  patientMembershipsActions,
  patientMembershipsSelectors,
  // @ts-ignore
} from '~/store/duck/patientMemberships'
import { getFeatureToggle } from '~/store/reducers/constants'
import { Membership } from '~/types'

import MembershipTablePatientRow from './MembershipTablePatientRow'

const useStyles = makeStyles(
  (theme) => ({
    rowContainer: {
      backgroundColor: 'unset',
    },
    rowContainerNoColoring: {
      borderTop: 'none',
      borderBottom: 'none',
    },
    tableHeader: {
      border: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableBackground,
    },
    text: {
      fontSize: '1.4rem',
    },
  }),
  { name: 'MembershipTablePatientsView' },
)

const getColumns = (isWellnessplanSharingEnabled: boolean) =>
  [
    {
      width: 4,
    },
    {
      label: i18n.t('Common:TIER'),
      width: 3,
      wrap: 'normal',
    },
    isWellnessplanSharingEnabled && {
      label: i18n.t('Common:PRACTICE'),
      width: 3,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:TYPE_ONE'),
      width: 2,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:PLAN_PERIOD'),
      width: 3,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:PAYMENTS.NEXT_PAYMENT'),
      width: 3,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:PAYMENTS.PAYMENT_METHOD'),
      width: 3,
    },
    {
      label: i18n.t('Common:MEMBER_SINCE'),
      width: 2,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:TERMS'),
      width: 1,
      wrap: 'normal',
    },
    {
      label: i18n.t('Common:CANCEL_ACTION'),
      width: 1,
      wrap: 'normal',
    },
  ].filter(Boolean) as PrimitiveTableColumn[]

export const getMembershipPatientRowColoring = (
  theme: Theme,
  item: Membership,
) => {
  if (item.state === WellnessPlans.MembershipStatus.PAUSED) {
    return theme.colors.important
  }
  return null
}

interface MembershipTablePatientsViewProps {
  clientId?: string
}

const MembershipTablePatientsView = ({
  clientId,
}: MembershipTablePatientsViewProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation('Common')
  const theme = useTheme()

  const list: string[] = useSelector(patientMembershipsSelectors.getList)
  const patientMemberships: Membership[] = useSelector(
    patientMembershipsSelectors.getMultipleItems(list),
  )
  const totalCount: number = useSelector(
    patientMembershipsSelectors.getTotalCount,
  )
  const hasOpenDialogs = useSelector(getHasOpenDialogs)

  const columns = getColumns(
    useSelector(getFeatureToggle(FeatureToggle.WELLNESSPLAN_SHARING)),
  )

  const getActualRowColoring = curry(getMembershipPatientRowColoring)(theme)

  useEffect(() => {
    if (clientId) {
      dispatch(
        patientMembershipsActions.fetchList(
          0,
          Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
          clientId,
        ),
      )
    }
  }, [clientId])

  useInterval(() => {
    if (!hasOpenDialogs && clientId) {
      dispatch(
        patientMembershipsActions.refreshList(
          0,
          Math.max(patientMemberships.length - 1, 0),
          clientId,
        ),
      )
    }
  }, Defaults.BALANCE_PAGE_UPDATE_INTERVAL)

  const isItemLoaded = (index: number) => Boolean(patientMemberships[index])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(
      patientMembershipsActions.fetchMoreItemsForList(
        startIndex,
        endIndex,
        clientId,
      ),
    )
  }

  return (
    <Grid container item>
      <Text mb={1} px={2} variant="h4">
        {t('Common:ACTIVE_PLANS')}
      </Text>
      <PrimitiveTableWithSearchHighlights
        useWindowScroll
        HeaderProps={{
          classes: {
            tableHeader: classes.tableHeader,
          },
        }}
        RowComponent={MembershipTablePatientRow}
        columns={columns}
        expandable={false}
        getRowColoring={getActualRowColoring}
        isItemLoaded={isItemLoaded}
        itemSpacing={0}
        list={patientMemberships}
        loadMoreItems={loadMoreItems}
        rowClasses={{
          container: classes.rowContainer,
          containerNoColoring: classes.rowContainerNoColoring,
          text: classes.text,
        }}
        totalCount={totalCount}
      />
    </Grid>
  )
}

export default MembershipTablePatientsView
