import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box } from '@mui/material'
import { CircularProgressOverlay } from '@pbt/pbt-ui-components'

import {
  clearAutoRenewingPlansInfo,
  fetchAutoRenewingPlansInfo,
} from '~/store/actions/wellnessPlans'
import {
  getAutoRenewingPlansInfo,
  getWellnessPlansIsLoading,
} from '~/store/reducers/wellnessPlans'

import { VerifyAutoRenewingPlansContainer } from './VerifyAutoRenewingPlansContainer'

interface AutoRenewPlanLinkingProps {
  onValidStateChange: (isValid: boolean) => void
}

export const AutoRenewPlanLinking = ({
  onValidStateChange,
}: AutoRenewPlanLinkingProps) => {
  const [isValid, setIsValid] = useState(true)
  const dispatch = useDispatch()
  const isLoading = useSelector(getWellnessPlansIsLoading)
  const autoRenewingPlansInfo = useSelector(getAutoRenewingPlansInfo)

  const showProgress =
    isLoading ||
    !autoRenewingPlansInfo?.plansMap ||
    !autoRenewingPlansInfo?.versionsList ||
    !autoRenewingPlansInfo?.versionsMap

  useEffect(() => {
    dispatch(fetchAutoRenewingPlansInfo())
    return () => {
      dispatch(clearAutoRenewingPlansInfo())
    }
  }, [])

  const handleValidityChange = (isTableValid: boolean) => {
    setIsValid(isTableValid)
    onValidStateChange(isTableValid)
  }

  return (
    <>
      <CircularProgressOverlay open={showProgress} />
      <Box sx={{ minHeight: 300, padding: 2 }}>
        {!showProgress && (
          <VerifyAutoRenewingPlansContainer
            plansList={autoRenewingPlansInfo.plansList}
            plansMap={autoRenewingPlansInfo.plansMap}
            showAlert={!isValid}
            versionsList={autoRenewingPlansInfo.versionsList}
            versionsMap={autoRenewingPlansInfo.versionsMap}
            onValidityChange={handleValidityChange}
          />
        )}
      </Box>
    </>
  )
}
