import React from 'react'
import {
  ContainerProps,
  css,
  styled,
  Table,
  TableCell,
  TableRow,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { Field, PuiTextArea } from '@pbt/pbt-ui-components'

const Container = styled(Table, {
  shouldForwardProp: (props) => props !== 'backgroundColor',
})<ContainerProps & { backgroundColor?: keyof Theme['colors'] }>`
  width: 100%;
  ${({ theme, backgroundColor }) =>
    backgroundColor
      ? css`
          background-color: ${theme.colors[backgroundColor]};
        `
      : ''}
`

const StyledTableCell = styled(TableCell)`
  padding: 4px;
`

const FieldsContainer = styled(TableRow)``

export interface PreviousMedicalHistoryItemProps {
  backgroundColor?: keyof Theme['colors']
  fields?: { field: React.ReactNode; name: string; width: string }[]
  notes?: Field
}

export const PreviousMedicalHistoryItem = ({
  fields,
  notes,
  backgroundColor,
}: PreviousMedicalHistoryItemProps) => (
  <Container backgroundColor={backgroundColor}>
    {fields?.map(({ width, name }) => <col key={name} width={width} />)}
    <FieldsContainer>
      {fields?.map(({ name, field }) => (
        <StyledTableCell key={name}>{field}</StyledTableCell>
      ))}
    </FieldsContainer>
    <FieldsContainer>
      <StyledTableCell colSpan={fields?.length}>
        <PuiTextArea field={notes} />
      </StyledTableCell>
    </FieldsContainer>
  </Container>
)
