import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Nil, NumberUtils, Text } from '@pbt/pbt-ui-components'

import { Payment as GqlPayment } from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'
import { getUser } from '~/store/reducers/users'
import { Payment } from '~/types'
import { getPaymentMethodString } from '~/utils/paymentUtils'

const useStyles = makeStyles(
  (theme) => ({
    root: {
      background: theme.colors.tableLeftColumnBackground,
    },
  }),
  { name: 'PaymentClientNotesSection' },
)

export interface PaymentClientNotesSectionProps {
  clientId: string | Nil
  depositExceedsInvoiceAmountRefund?: boolean
  invoiceRefundAmount?: number
  invoiceRefundOriginalPayments?: GqlPayment[]
  isInvoiceRefund?: boolean
  isPayout?: boolean
  isRefund: boolean
  isReverse?: boolean
  isVoid?: boolean
  onAccountOriginPayment?: Payment | Nil
  payment: Payment
  showBalance?: boolean
}

const PaymentClientNotesSection = ({
  payment,
  invoiceRefundAmount,
  invoiceRefundOriginalPayments = [],
  isPayout,
  isRefund,
  isVoid,
  isReverse = false,
  isInvoiceRefund,
  showBalance,
  clientId,
  depositExceedsInvoiceAmountRefund,
  onAccountOriginPayment,
}: PaymentClientNotesSectionProps) => {
  const classes = useStyles()
  const client = useSelector(getUser(clientId))
  const { t } = useTranslation(['Common', 'Invoices'])
  const existingBalanceLabel = t(
    'Invoices:PAYMENTS.PAYMENT_CLIENT_NOTES_SECTION.EXISTING_BALANCE',
  )
  const prepaidExpensesLabel = t(
    'Invoices:PAYMENTS.PAYMENT_CLIENT_NOTES_SECTION.PREPAID_EXPENSES',
  )

  const isRefundLabel = isInvoiceRefund
    ? invoiceRefundOriginalPayments.length > 1
      ? t('Invoices:PAYMENTS.PAYMENT_CLIENT_NOTES_SECTION.TOTAL_REFUNDABLE')
      : t('Invoices:TO_BE_REFUNDED')
    : depositExceedsInvoiceAmountRefund
      ? t('Invoices:PAYMENTS.PAYMENT_CLIENT_NOTES_SECTION.DEPOSIT_PAID')
      : t('Invoices:PAYMENTS.PAYMENT_CLIENT_NOTES_SECTION.TOTAL_PAID')

  const balanceLabel =
    client?.balance && client?.balance >= 0
      ? existingBalanceLabel
      : prepaidExpensesLabel

  const paymentMethodString = getPaymentMethodString(payment)

  const getInvoiceRefundPaymentDetails = () => {
    if (
      (isInvoiceRefund && invoiceRefundOriginalPayments.length === 1) ||
      isPayout
    ) {
      const originalPayment = isPayout
        ? onAccountOriginPayment
        : invoiceRefundOriginalPayments[0]
      const body = getPaymentMethodString(originalPayment)
      const prefix =
        originalPayment?.method?.name === 'Check'
          ? t('Common:PAYMENTS.PAID_BY')
          : originalPayment?.method?.name === 'Cash'
            ? t('Common:PAYMENTS.PAID')
            : `${t('Common:PAYMENTS.PAID')} ${t('Common:PAYMENTS.PAID_ON')}`
      const paidAmount = NumberUtils.formatMoney(originalPayment?.amount)
      return `${prefix} ${body}: ${paidAmount}`
    }

    return ''
  }

  return (
    <Grid container item className={classes.root} p={3}>
      {(isReverse || isVoid) && (
        <Text variant="body2">
          {t('Common:PAYMENTS.PAID')}
          {paymentMethodString
            ? ` ${t('Common:PAYMENTS.PAID_ON')} ${paymentMethodString}`
            : ''}
          {': '}
          {NumberUtils.formatMoney(payment.amount)}
        </Text>
      )}
      {isRefund && (
        <Text variant="body2">
          {isRefundLabel}:{' '}
          {NumberUtils.formatMoney(
            isInvoiceRefund ? invoiceRefundAmount : payment.amount,
          )}
        </Text>
      )}
      {isPayout && (
        <Grid container>
          <Typography.H3>{`${t('Common:PAYMENTS.TO_BE_PAID_OUT')}: ${NumberUtils.formatMoney(payment?.refundableAmount)}`}</Typography.H3>
          <Typography.Paragraph>
            {getInvoiceRefundPaymentDetails()}
          </Typography.Paragraph>
        </Grid>
      )}
      {isInvoiceRefund && invoiceRefundOriginalPayments.length === 1 && (
        <Grid>
          <Text variant="body2">{getInvoiceRefundPaymentDetails()}</Text>
        </Grid>
      )}
      {showBalance && (
        <Text variant="body2">
          {balanceLabel}: {NumberUtils.formatMoney(client?.balance)}
        </Text>
      )}
      {client?.notes && (
        <Grid container direction="column" justifyContent="center">
          <Text
            mt={showBalance ? 1.5 : 0}
            strong={showBalance}
            variant="subheading3"
          >
            {t('Common:CLIENT_NOTES')}:
          </Text>
          <Text variant="body2">{client.notes}</Text>
        </Grid>
      )}
    </Grid>
  )
}

export default PaymentClientNotesSection
