enum DialogNames {
  ADD_ALERTS_DIALOG = 'ADD_ALERTS_DIALOG',
  ADD_BUSINESS_CHILD = 'ADD_BUSINESS_CHILD',
  ADD_CHARGE_SHEET_ITEM = 'ADD_CHARGE_SHEET_ITEM',
  ADD_CLIENT_PATIENT_TO_CONVERSATION = 'ADD_CLIENT_PATIENT_TO_CONVERSATION',
  ADD_CLIENT_PATIENT_TO_RX_REQUEST = 'ADD_CLIENT_PATIENT_TO_RX_REQUEST',
  ADD_CLIENT_PAYMENT = 'ADD_CLIENT_PAYMENT',
  ADD_DATE_TIME_PICKER = 'ADD_DATE_TIME_PICKER',
  ADD_INVOICE_ITEM = 'ADD_INVOICE_ITEM',
  ADD_NEW_LAB_TEST = 'ADD_NEW_LAB_TEST',
  ADD_PAYMENT = 'ADD_PAYMENT',
  ADD_PMR = 'ADD_PMR',
  ADD_REMINDER = 'ADD_REMINDER',
  ADD_REMINDER_PROTOCOL_ITEMS = 'ADD_REMINDER_PROTOCOL_ITEMS',
  ADD_SHIPMENT_ITEM = 'ADD_SHIPMENT_ITEM',
  ADD_SINGLE_CHECKBOX = 'ADD_SINGLE_CHECKBOX',
  ADD_TO_APPOINTMENT = 'ADD_TO_APPOINTMENT',
  ADD_WELLNESS_PLAN_COVERAGE_ITEMS = 'ADD_WELLNESS_PLAN_COVERAGE_ITEMS',
  ADDENDUM = 'ADDENDUM',
  ADJUST_TIMETABLE_COLUMNS = 'ADJUST_TIMETABLE_COLUMNS',
  ADJUSTMENT_DIALOG = 'ADJUSTMENT_DIALOG',
  ADVANCED_CLIENTS_SEARCH = 'ADVANCED_CLIENTS_SEARCH',
  APPLY_PAYMENT_TO_INVOICE = 'APPLY_PAYMENT_TO_INVOICE',
  APPOINTMENT_CANCELLATION_REASON = 'APPOINTMENT_CANCELLATION_REASON',
  APPOINTMENT_CONFIGURATION_CONFIRMATION = 'APPOINTMENT_CONFIGURATION_CONFIRMATION',
  APPOINTMENT_NEW_CLIENT_CONFIRMATION = 'APPOINTMENT_NEW_CLIENT_CONFIRMATION',
  APPOINTMENT_REPORT_CARD = 'APPOINTMENT_REPORT_CARD',
  APPOINTMENTS_LIST_FOR_ESTIMATE = 'APPOINTMENTS_LIST_FOR_ESTIMATE',
  ASSIGN_IMAGE_TO_PATIENT = 'ASSIGN_IMAGE_TO_PATIENT',
  ASSIGN_TASKS = 'ASSIGN_TASKS',
  ASSIGN_TEAM_MEMBER = 'ASSIGN_TEAM_MEMBER',
  ASSIGNED_VET_VALIDATION = 'ASSIGNED_VET_VALIDATION',
  ATTACHMENT_HISTORY = 'ATTACHMENT_HISTORY',
  ATTACHMENT_LIGHTBOX = 'ATTACHMENT_LIGHTBOX',
  AUTO_REPLY_MESSAGE_PREVIEW_DIALOG = 'AUTO_REPLY_MESSAGE_PREVIEW_DIALOG',
  AUTOMATED_PATIENT_UPDATE_ALERT = 'AUTOMATED_PATIENT_UPDATE_ALERT',
  AUTOMATIC_COMMUNICATION = 'AUTOMATIC_COMMUNICATION',
  AUTOMATIC_COMMUNICATION_PREVIEW = 'AUTOMATIC_COMMUNICATION_PREVIEW',
  AVAILABILITY_RULE = 'AVAILABILITY_RULE',
  BATCH_IMAGING_ORDERS_MANAGEMENT = 'BATCH_IMAGING_ORDERS_MANAGEMENT',
  BATCH_IMAGING_ORDERS_MANAGEMENT_V2 = 'BATCH_IMAGING_ORDERS_MANAGEMENT_V2',
  BATCH_INVOICE = 'BATCH_INVOICE',
  BOOP_SLA_MESSAGES_PREVIEW_DIALOG = 'BOOP_SLA_MESSAGES_PREVIEW_DIALOG',
  BULK_ADJUSTMENT_DIALOG = 'BULK_ADJUSTMENT_DIALOG',
  BULK_PRICES_CLOSE = 'BULK_PRICES_CLOSE',
  BUNDLE = 'BUNDLE',
  BUNDLE_CUSTOM_PRICE = 'BUNDLE_CUSTOM_PRICE',
  BUNDLE_TASK = 'BUNDLE_TASK',
  CANCEL_MEMBERSHIP = 'CANCEL_MEMBERSHIP',
  CANCEL_MEMBERSHIP_DETAILS = 'CANCEL_MEMBERSHIP_DETAILS',
  CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP = 'CANCEL_FUTURE_APPOINTMENTS_AND_MEMBERSHIP',
  CHANGE_PET_PARENT = 'CHANGE_PET_PARENT',
  CHARGE_SHEET_LAB_ORDER_MANAGEMENT_DIALOG = 'CHARGE_SHEET_LAB_ORDER_MANAGEMENT_DIALOG',
  CHEWY_PAYMENT_DIALOG = 'CHEWY_PAYMENT_DIALOG',
  CHOOSE_AVATAR = 'CHOOSE_AVATAR',
  CHOOSE_SHIPMENT_ITEM_DIALOG = 'CHOOSE_SHIPMENT_ITEM_DIALOG',
  CHOOSE_ZOOM_USER_DIALOG = 'CHOOSE_ZOOM_USER_DIALOG',
  CLIENT_DETAILS_EDIT = 'CLIENT_DETAILS_EDIT',
  CLIENT_DUPLICATES = 'CLIENT_DUPLICATES',
  CLIENT_NEW = 'CLIENT_NEW',
  CLIENT_PREFERENCES = 'CLIENT_PREFERENCES',
  CLIENT_SURVEY = 'CLIENT_SURVEY',
  CO_PET_PARENT_DIALOG = 'CO_PET_PARENT_DIALOG',
  COMMUNICATION_SAVE_AS_CLIENT_EMAIL = 'COMMUNICATION_SAVE_AS_CLIENT_EMAIL',
  CONFIRM_CLOSE_DIALOG = 'CONFIRM_CLOSE_DIALOG',
  CONTACT = 'CONTACT',
  CONVERSATION_ATTACHMENT_DIALOG = 'CONVERSATION_ATTACHMENT_DIALOG',
  CONVERSATION_DELETE_DIALOG = 'CONVERSATION_DELETE_DIALOG',
  CONVERSATION_INTERNAL_NOTE = 'CONVERSATION_INTERNAL_NOTE',
  CONVERSATION_MULTI_ATTACHMENT_DIALOG = 'CONVERSATION_MULTI_ATTACHMENT_DIALOG',
  CORE_MIGRATION_DIALOG = 'CORE_MIGRATION_DIALOG',
  COVETRUS_INTEGRATION_DIALOG = 'COVETRUS_INTEGRATION_DIALOG',
  CREATE_PRACTICE = 'CREATE_PRACTICE',
  CREATE_PRACTICE_GROUP = 'CREATE_PRACTICE_GROUP',
  CREATED_CONVERSATIONS_INFO_DIALOG = 'CREATED_CONVERSATIONS_INFO_DIALOG',
  CREDIT_ADJUSTMENT_DIALOG = 'CREDIT_ADJUSTMENT_DIALOG',
  DECLINE_FINALIZED_LAB_TEST = 'DECLINE_FINALIZED_LAB_TEST',
  DECLINE_LAB_ORDER = 'DECLINE_LAB_ORDER',
  DELETE_LINE_ITEMS = 'DELETE_LINE_ITEMS',
  DIAGNOSIS_HISTORY = 'DIAGNOSIS_HISTORY',
  DISMISSIBLE_ALERT = 'DISMISSIBLE_ALERT',
  DOCTOR_LICENSE_DIALOG = 'DOCTOR_LICENSE_DIALOG',
  DOCTOR_SIGNATURE_DIALOG = 'DOCTOR_SIGNATURE_DIALOG',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_FORM_PREVIEW_DIALOG = 'DOCUMENT_FORM_PREVIEW_DIALOG',
  DOCUMENT_RADIO_GROUP_DIALOG = 'DOCUMENT_RADIO_GROUP_DIALOG',
  DRUG_PRESCRIPTION_DIALOG = 'DRUG_PRESCRIPTION_DIALOG',
  DRUG_REFILL_DIALOG = 'DRUG_REFILL_DIALOG',
  DYMO_ERROR = 'DYMO_ERROR',
  EMAIL_APPOINTMENT = 'EMAIL_APPOINTMENT',
  EMAIL_APPOINTMENT_CONFIRMATION = 'EMAIL_APPOINTMENT_CONFIRMATION',
  EMAIL_ATTACHMENT_DIALOG = 'EMAIL_ATTACHMENT_DIALOG',
  EMAIL_CHARGE_SHEET = 'EMAIL_CHARGE_SHEET',
  EMAIL_INVOICE = 'EMAIL_INVOICE',
  EMAIL_LAB_ORDER_RESULT = 'EMAIL_LAB_ORDER_RESULT',
  EMAIL_POSTED_INVOICE = 'EMAIL_POSTED_INVOICE',
  EMAIL_PRESCRIPTION = 'EMAIL_PRESCRIPTION',
  EMAIL_TEMPLATE_PREVIEW_DIALOG = 'EMAIL_TEMPLATE_PREVIEW_DIALOG',
  EMERGENCY_CONTACT_DIALOG = 'EMERGENCY_CONTACT_DIALOG',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  ESTIMATE_APPROVAL_DIALOG = 'ESTIMATE_APPROVAL_DIALOG',
  ESTIMATES_LIST = 'ESTIMATES_LIST',
  EVENT = 'EVENT',
  EXPANDER_CONFIRM_CLOSE = 'EXPANDER_CONFIRM_CLOSE',
  FINALIZE_CHEWY_ACTIVE_RX_DIALOG = 'FINALIZE_CHEWY_ACTIVE_RX_DIALOG',
  FINALIZE_LAB_ORDER = 'FINALIZE_LAB_ORDER',
  FOOD_PRESCRIPTION_DIALOG = 'FOOD_PRESCRIPTION_DIALOG',
  FOOD_REFILL_DIALOG = 'FOOD_REFILL_DIALOG',
  GLOBAL_VARIATION_SELECT = 'GLOBAL_VARIATION_SELECT',
  GENERAL_INFORMATION_LOCATION = 'GENERAL_INFORMATION_LOCATION',
  IMAGING_ORDER = 'IMAGING_ORDER',
  IMPORT_ALREADY_DONE = 'IMPORT_ALREADY_DONE',
  INVENTORY = 'INVENTORY',
  INVOICE = 'INVOICE',
  INVOICE_DISCOUNT_REASON = 'INVOICE_DISCOUNT_REASON',
  INVOICE_MISSING_FIELDS_DIALOG = 'INVOICE_MISSING_FIELDS_DIALOG',
  KIOSK = 'KIOSK',
  KIOSK_PREVIEW = 'KIOSK_PREVIEW',
  LAB_ORDER_MANAGEMENT_DIALOG = 'LAB_ORDER_MANAGEMENT_DIALOG',
  LAB_RESULT_ASSIGNMENT = 'LAB_RESULT_ASSIGNMENT',
  LAB_TEST_ATTACHMENT_DIALOG = 'LAB_TEST_ATTACHMENT_DIALOG',
  LAB_TEST_PRICE = 'LAB_TEST_PRICE',
  LAB_TEST_PRICE_VALIDATION = 'LAB_TEST_PRICE_VALIDATION',
  LAB_VENDOR_PROVIDERS_DIALOG = 'LAB_VENDOR_PROVIDERS_DIALOG',
  LICENSE = 'LICENSE',
  LINK_CHEWY_ACCOUNT = 'LINK_CHEWY_ACCOUNT',
  LINK_INDIVIDUAL_PATIENT_DIALOG = 'LINK_INDIVIDUAL_PATIENT_DIALOG',
  LOST_ACCESS_TO_CLINIC_ALERT_DIALOG = 'LOST_ACCESS_TO_CLINIC_ALERT_DIALOG',
  MATCH_PET_PATIENT_DIALOG = 'MATCH_PET_PATIENT_DIALOG',
  MEMBERSHIP_PAYMENT_DETAILS = 'MEMBERSHIP_PAYMENT_DETAILS',
  MEMBERSHIP_SIGN_UP = 'MEMBERSHIP_SIGN_UP',
  MIGRATION_ACTIVATION_TOKEN = 'MIGRATION_ACTIVATION_TOKEN',
  MIGRATION_UPLOAD_ADDITIONAL_FILES = 'MIGRATION_UPLOAD_ADDITIONAL_FILES',
  MIGRATION_V2_DIALOG = 'MigrationV2Dialog',
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  MULTI_ATTACHMENT_HISTORY = 'MULTI_ATTACHMENT_HISTORY',
  MULTI_VALIDATION_ERROR = 'MULTI_VALIDATION_ERROR',
  MULTIPLE_SELECT_DIALOG = 'MULTIPLE_SELECT_DIALOG',
  MULTIPLE_TASKS = 'MULTIPLE_TASKS',
  NEW_BENCHMARKING_ACTIVATION_KEY = 'NEW_BENCHMARKING_ACTIVATION_KEY',
  NEW_CONVERSATION_WITH_STEPS = 'NEW_CONVERSATION_WITH_STEPS',
  NEW_REMINDER_PROTOCOL_GROUP = 'NEW_REMINDER_PROTOCOL_GROUP',
  NOT_FINALIZED_ORDERS = 'NOT_FINALIZED_ORDERS',
  NOTE_HISTORY = 'NOTE_HISTORY',
  NOTES = 'NOTES',
  NOTIFICATION_STYLES_PREVIEW_DIALOG = 'NOTIFICATION_STYLES_PREVIEW_DIALOG',
  ON_GOING_STANDARDIZATION_DIALOG = 'ON_GOING_STANDARDIZATION_DIALOG',
  ON_HAND_CATALOG_PRINT = 'ON_HAND_CATALOG_PRINT',
  ORDER_RESULTS = 'ORDER_RESULTS',
  OTC_VALIDATION = 'OTC_VALIDATION',
  PATIENT = 'PATIENT',
  PATIENT_MEMBERSHIP = 'PATIENT_MEMBERSHIP',
  PATIENT_MEMBERSHIP_PAYMENT_INFO = 'PATIENT_MEMBERSHIP_PAYMENT_INFO',
  PATIENT_MEMBERSHIP_PAYMENT_LINK = 'PATIENT_MEMBERSHIP_PAYMENT_LINK',
  PATIENT_MISSING_FIELDS_DIALOG = 'PATIENT_MISSING_FIELDS_DIALOG',
  PATIENT_PREFERENCES = 'PATIENT_PREFERENCES',
  PATIENTS_LIST = 'PATIENTS_LIST',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',
  PAYMENT_REQUEST = 'PAYMENT_REQUEST',
  PET_FRIEND = 'PET_FRIEND',
  PET_PARENT_CHANGED_DIALOG = 'PET_PARENT_CHANGED_DIALOG',
  PRACTICE_CLONING_EXPORT_DIALOG = 'PRACTICE_CLONING_EXPORT_DIALOG',
  PRACTICE_CLONING_IMPORT_DIALOG = 'PRACTICE_CLONING_IMPORT_DIALOG',
  PRESCRIPTION_DECLINE_REASON = 'PRESCRIPTION_DECLINE_REASON',
  PRESCRIPTION_MISSING_FIELDS_DIALOG = 'PRESCRIPTION_MISSING_FIELDS_DIALOG',
  PREVIEW_REMINDER_TEMPLATE = 'PREVIEW_REMINDER_TEMPLATE',
  PREVIEW_WELLNESS_PROMOTION = 'PREVIEW_WELLNESS_PROMOTION',
  PRICE = 'PRICE',
  PRINT_CAGE_LABEL = 'PRINT_CAGE_LABEL',
  PRINT_CHARGE_SHEET = 'PRINT_CHARGE_SHEET',
  PRINT_FOLDER_LABEL = 'PRINT_FOLDER_LABEL',
  PRINT_IN_HOUSE_ORDER_LABEL = 'PRINT_IN_HOUSE_ORDER_LABEL',
  PRINT_INVOICE = 'PRINT_INVOICE',
  PRINT_LAB_ORDER_LABEL = 'PRINT_LAB_ORDER_LABEL',
  PRINT_POSTED_INVOICE = 'PRINT_POSTED_INVOICE',
  PRINT_PRACTICE_ADDRESS_LABEL = 'PRINT_PRACTICE_ADDRESS_LABEL',
  PROFILE = 'PROFILE',
  PROOF_OF_VACCINATION = 'PROOF_OF_VACCINATION',
  QUESTION = 'QUESTION',
  RABIES_VACCINATIONS = 'RABIES_VACCINATIONS',
  RECURRING_EVENT = 'RECURRING_EVENT',
  REDEEM_LOYALTY_POINTS = 'REDEEM_LOYALTY_POINTS',
  REFUND_PAYMENT = 'REFUND_PAYMENT',
  REMINDER_COMMUNICATION = 'REMINDER_COMMUNICATION',
  REMINDER_DETAILS = 'REMINDER_DETAILS',
  REMINDER_PROTOCOL = 'REMINDER_PROTOCOL',
  REMINDERS_LIST = 'REMINDERS_LIST',
  REMOVE_FINALIZED_LAB_TEST = 'REMOVE_FINALIZED_LAB_TEST',
  REMOVE_LAB_ORDER = 'REMOVE_LAB_ORDER',
  REMOVE_MEMBER = 'REMOVE_MEMBER',
  REMOVE_ORDERS_FROM_POSTED_INVOICE = 'REMOVE_ORDERS_FROM_POSTED_INVOICE',
  REPEAT_TASKS = 'REPEAT_TASKS',
  REPORT_UNAVAILABLE = 'REPORT_UNAVAILABLE',
  REQUIRED_DEPOSIT = 'REQUIRED_DEPOSIT',
  REVIEW_EXCEPTIONS = 'REVIEW_EXCEPTIONS',
  RHAPSODY_PAY_SETUP = 'RHAPSODY_PAY_SETUP',
  ROLES = 'ROLES',
  SAVE_INVOICE_ALERT = 'SAVE_INVOICE_ALERT',
  SELECT_ATTACHMENT = 'SELECT_ATTACHMENT',
  SELECT_BENEFIT_APPOINTMENT_TYPE = 'SELECT_BENEFIT_APPOINTMENT_TYPE',
  SELECT_CONTACT = 'SELECT_CONTACT',
  SELECT_PIMS = 'SELECT_PIMS',
  SEND_BOOP_LINK = 'SEND_BOOP_LINK',
  SEND_HYBRID_MEMBERSHIP_INVITE = 'SEND_HYBRID_MEMBERSHIP_INVITE',
  SHARE_MEDICAL_HISTORY = 'SHARE_MEDICAL_HISTORY',
  SHIPMENT = 'SHIPMENT',
  SHIPMENT_ITEM = 'SHIPMENT_ITEM',
  SIGNATURE_DIALOG = 'SIGNATURE_DIALOG',
  SOAP_ATTACHMENT = 'SOAP_ATTACHMENT',
  SOAP_AUTOCHARGE_ALERT = 'SOAP_AUTOCHARGE_ALERT',
  SOAP_EXAM_FINDINGS_VALIDATION_ERROR = 'SOAP_EXAM_FINDINGS_VALIDATION_ERROR',
  SOAP_FINALIZED_ALERT = 'SOAP_FINALIZED_ALERT',
  SOAP_MULTI_ATTACHMENT = 'SOAP_MULTI_ATTACHMENT',
  SOAP_NOTES_VALIDATION_ERROR = 'SOAP_NOTES_VALIDATION_ERROR',
  SOAP_ORDER_VALIDATION_ERROR_ALERT = 'SOAP_ORDER_VALIDATION_ERROR_ALERT',
  SOAP_TEMPLATE_TAB = 'SOAP_TEMPLATE_TAB',
  SOAP_VALIDATION = 'SOAP_VALIDATION',
  SOAP_WRAP_UP_LEAVE = 'SOAP_WRAP_UP_LEAVE',
  SPACE = 'SPACE',
  SPACE_SELECT = 'SPACE_SELECT',
  SWITCH_USER = 'SWITCH_USER',
  TASK = 'TASK',
  TEXT_ENTRY = 'TEXT_ENTRY',
  TEAM_MEMBER = 'TEAM_MEMBER',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  TIME_TRACKER = 'TIME_TRACKER',
  TIME_TRACKER_EDIT = 'TIME_TRACKER_EDIT',
  TYPOGRAPHY_DIALOG = 'TYPOGRAPHY_DIALOG',
  UNDO_PAYMENT = 'UNDO_PAYMENT',
  UPLOAD_IMAGE = 'UPLOAD_IMAGE',
  UPLOAD_SESSION_FILES = 'UPLOAD_SESSION_FILES',
  VACCINE_CERTIFICATE_PREVIEW = 'VACCINE_CERTIFICATE_PREVIEW',
  VACCINE_CERTIFICATE_VALIDATION = 'VACCINE_CERTIFICATE_VALIDATION',
  VACCINE_DETAILS = 'VACCINE_DETAILS',
  VACCINE_HISTORY = 'VACCINE_HISTORY',
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  VARIATION = 'VARIATION',
  VIEW_ORDER_RESULTS_FRAME = 'VIEW_ORDER_RESULTS_FRAME',
  VITALS = 'VITALS',
  VITALS_CONFIRMATION = 'VITALS_CONFIRMATION',
  WELLNESS_PLAN_BENEFITS = 'WELLNESS_PLAN_BENEFITS',
  WELLNESS_PLAN_EXTRA = 'WELLNESS_PLAN_EXTRA',
  WELLNESS_PLAN_PACKAGE = 'WELLNESS_PLAN_PACKAGE',
  WELLNESS_PLAN_SAVINGS = 'WELLNESS_PLAN_SAVINGS',
  WELLNESS_PLAN_TIER = 'WELLNESS_PLAN_TIER',
}

export default DialogNames

export enum ConfirmAlertType {
  ADDITIONAL_DISCOUNT = 'ADDITIONAL_DISCOUNT',
  BANK_CARD_WARNING = 'BANK_CARD_WARNING',
  CHOOSE_SHIPMENT_ITEM = 'CHOOSE_SHIPMENT_ITEM',
  CLONE_ESTIMATE = 'CLONE_ESTIMATE',
  COMPLETE_TASK = 'COMPLETE_TASK',
  DELETE_BUSINESS_GROUP = 'DELETE_BUSINESS_GROUP',
  DISCONNECT_CHEWY_ACCOUNT = 'DISCONNECT_CHEWY_ACCOUNT',
  ENABLE_SSO_WARNING = 'ENABLE_SSO_WARNING',
  ESTIMATE_CANT_BE_MODIFIED = 'ESTIMATE_CANT_BE_MODIFIED',
  EXAMINATION_CATEGORY = 'EXAMINATION_CATEGORY',
  EXAMINATION_FINDING = 'EXAMINATION_FINDING',
  INLINE_CLIENT_AND_PATIENT_EDIT = 'INLINE_CLIENT_AND_PATIENT_EDIT',
  INVOICE_CLOSE = 'INVOICE_CLOSE',
  INVOICE_PAID_STATUS_CONFIRM = 'INVOICE_PAID_STATUS_CONFIRM',
  INVOICE_TABLE_ITEM = 'INVOICE_TABLE_ITEM',
  ITEM_DISCOUNT = 'ITEM_DISCOUNT',
  LANDING_LAYOUT_RESET_DEFAULT = 'LANDING_LAYOUT_RESET_DEFAULT',
  POST_INVOICE = 'POST_INVOICE',
  POST_INVOICE_FAILURE = 'POST_INVOICE_FAILURE',
  REFUND_INVOICE_PAYMENT = 'REFUND_INVOICE_PAYMENT',
  REORDER_DECLINED_LABTEST = 'REORDER_DECLINED_LABTEST',
  SOAP_LEAVE_FOR_ASSIGNMENT = 'SOAP_LEAVE_FOR_ASSIGNMENT',
  SOAP_LOCK = 'SOAP_LOCK',
  SOAP_ORDER = 'SOAP_ORDER',
  SOAP_RAIL_DIFFERENTIAL_DIAGNOSE = 'SOAP_RAIL_DIFFERENTIAL_DIAGNOSE',
  SOAP_RAIL_EXAMINATION = 'SOAP_RAIL_EXAMINATION',
  SOAP_RAIL_ORDER = 'SOAP_RAIL_ORDER',
  START_ALREADY_DONE_IMPORT = 'START_ALREADY_DONE_IMPORT',
  SUGGEST_UNBLOCK_INVOICE = 'SUGGEST_UNBLOCK_INVOICE',
  VOID_INVOICE = 'VOID_INVOICE',
  WP_AUTO_RENEWAL_WARNING = 'WP_AUTO_RENEWAL_WARNING',
  WP_ACTIVATING_WARNING = 'WP_ACTIVATING_WARNING',
}
