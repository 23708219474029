import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import type { PrimitiveTableColumn } from '@pbt/pbt-ui-components'
import { DateUtils, Defaults, NumberUtils } from '@pbt/pbt-ui-components'
import { ChevronDown, ChevronUp } from '@pbt/pbt-ui-components/src/icons'

import ExpandableTable from '~/components/common/lists/ExpandableTable'
import PrimitiveTableWithSearchHighlights from '~/components/common/lists/primitive-table/PrimitiveTableWithSearchHighlights'
import {
  fetchInventoryOrders,
  fetchMoreInventoryOrders,
  getInventoryOrdersFilters,
  getInventoryOrdersIsFetchingList,
  getInventoryOrdersList,
  getInventoryOrdersSortings,
  getInventoryOrdersTotalCount,
  getMultipleInventoryOrders,
  setInventoryOrderFilters,
  setInventoryOrderSortings,
} from '~/store/duck/inventoryOrders'
import { TableFilter } from '~/types'

import InventoryTableCategoryFilter from '../catalog/InventoryTableCategoryFilter'
import DistributorCell from '../shipments/DistributorCell'
import { InventoryOrderCategoryCell } from '../shipments/InventoryOrderCategoryCell'
import ShipmentStatusFilter from '../shipments/ShipmentStatusFilter'
import ShipmentStatusLabel from '../shipments/ShipmentStatusLabel'

interface OrdersComponentProps {
  headerButtons?: React.ReactNode
  headerTabs: React.ReactNode
}

const OrdersTableComponent = ({
  headerButtons,
  headerTabs,
}: OrdersComponentProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Admin'])

  const filters = useSelector(getInventoryOrdersFilters)
  const sortings = useSelector(getInventoryOrdersSortings)
  const inventoryOrdersList = useSelector(getInventoryOrdersList)
  const totalCount = useSelector(getInventoryOrdersTotalCount)
  const isFetchingList = useSelector(getInventoryOrdersIsFetchingList)
  const inventoryOrders = useSelector(
    getMultipleInventoryOrders(inventoryOrdersList),
  )

  useEffect(() => {
    dispatch(fetchInventoryOrders(0, Defaults.INFINITE_LIST_BATCH_LOAD_COUNT))
  }, [filters])

  const loadMoreItems = (startIndex: number, endIndex: number) => {
    dispatch(fetchMoreInventoryOrders(startIndex, endIndex))
  }

  const onApplyFilter = (filter: string, value: TableFilter) => {
    dispatch(setInventoryOrderFilters({ ...filters, [filter]: value }))
  }

  const onClearFilters = () => {
    dispatch(setInventoryOrderFilters({}))
  }

  const onApplySort = (sortKey: string, value: boolean) => {
    dispatch(
      setInventoryOrderSortings(
        sortings[sortKey]
          ? R.omit(['orderedDate'], sortings)
          : { ...sortings, [sortKey]: value },
      ),
    )
  }

  const onClearSortings = () => {
    dispatch(setInventoryOrderSortings({}))
  }

  const isItemLoaded = useCallback(
    (index: number) => Boolean(inventoryOrders[index]),
    [inventoryOrders],
  )

  const columns: PrimitiveTableColumn[] = [
    {
      label: t('Admin:CATALOG.ORDERS.ORDERED_DATE'),
      prop: ({ orderedDate }: { orderedDate: string }) =>
        DateUtils.formatDate(orderedDate) || '-',
      width: 2,
      sorting: 'orderedDate',
      SortingIcon: sortings.orderedDate ? ChevronUp : ChevronDown,
    },
    {
      label: t('Admin:CATALOG.ORDERS.PURCHASE_ORDER_NUMBER'),
      prop: 'orderNumber',
      width: 1,
    },
    {
      label: t('Admin:CATALOG.ORDERS.SUPPLIER'),
      prop: DistributorCell,
      width: 2,
    },
    {
      label: t('Admin:CATALOG.ORDERS.ORDERED_BY'),
      prop: 'orderedByName',
      width: 2,
    },
    {
      label: t('Common:CATEGORY'),
      prop: InventoryOrderCategoryCell,
      filter: 'categories',
      FilterComponent: InventoryTableCategoryFilter,
      width: 3,
    },
    {
      label: t('Admin:CATALOG.ORDERS.LINES_RECEIVED'),
      prop: ({
        linesReceived,
        totalLines,
      }: {
        linesReceived: number
        totalLines: number
      }) =>
        `${linesReceived} / ${totalLines} | ${NumberUtils.toPercentFormat(linesReceived / totalLines)}`,
      width: 2,
    },
    {
      filter: 'status',
      FilterComponent: ShipmentStatusFilter,
      label: t('Common:STATUS'),
      noTypography: true,
      prop: ShipmentStatusLabel,
      width: 2,
    },
    {
      label: t('Common:FLAGS'),
      prop: () => '',
      width: 1,
    },
    {
      label: t('Admin:CATALOG.ORDERS.LAST_RECEIVED'),
      prop: ({ lastReceivedDate }: { lastReceivedDate: string }) =>
        lastReceivedDate
          ? DateUtils.formatDateWithHours(lastReceivedDate)
          : '-',
      width: 2,
    },
  ]

  return (
    <ExpandableTable
      headerButtons={headerButtons}
      headerTabs={headerTabs}
      isLoading={isFetchingList}
      list={inventoryOrders}
      title={t('Common:INVENTORY')}
    >
      <PrimitiveTableWithSearchHighlights
        columns={columns}
        filters={filters}
        isItemLoaded={isItemLoaded}
        loadMoreItems={loadMoreItems}
        sortings={sortings}
        totalCount={totalCount}
        onApplyFilter={onApplyFilter}
        onApplySorting={onApplySort}
        onClearFilters={onClearFilters}
        onClearSortingItem={onClearSortings}
      />
    </ExpandableTable>
  )
}

export default OrdersTableComponent
