import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { FormControl, Grid, InputLabel } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  PuiCheckbox,
  PuiSelect,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { AppointmentCancellationReasonConstant } from '@pbt/pbt-ui-components/src/types/entities'

import { CancellationInfo } from '~/constants/cancellationReasonType'
import { getInternalCancellationReasons } from '~/store/reducers/constants'

const useStyles = makeStyles(
  () => ({
    checkbox: {
      display: 'flex',
      alignItems: 'flex-end',
    },
  }),
  { name: 'Appointment' },
)
export interface AppointmentCancellationDetailsProps {
  cancellationInfo: CancellationInfo
  onCancellationReasonChange: (
    value: AppointmentCancellationReasonConstant,
  ) => void
  onWaiveValueChange: (value: boolean) => void
  waiveLateCancellationFeeChecked: boolean
}
export const AppointmentCancellationDetails = ({
  cancellationInfo,
  waiveLateCancellationFeeChecked,
  onCancellationReasonChange,
  onWaiveValueChange,
}: AppointmentCancellationDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation(['Common'])
  const appointmentCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const { fields } = useFields(
    [
      {
        name: 'cancellationReason',
        label: t('Common:CANCELLATION_REASON'),
        type: 'select',
        initialValue: cancellationInfo.cancellationReasonId || '',
      },
      {
        name: 'waiveLateCancellationFee',
        label: t('Common:WAIVE_LATE_CANCELLATION_FEE'),
        initialValue: waiveLateCancellationFeeChecked || false,
      },
    ],
    false,
  )

  const { cancellationReason, waiveLateCancellationFee } = fields

  const onSelectReason = (value: string) => {
    const reason = appointmentCancellationReasons.filter(
      (reason) => reason.id === value,
    )
    if (reason.length > 0 && reason[0].name) {
      onCancellationReasonChange(reason[0])
      cancellationReason.setValue(reason[0].id)
    }
  }

  const onCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    onWaiveValueChange(event.target.checked)
    waiveLateCancellationFee.setValue(event.target.checked)
  }

  return (
    <Grid container item spacing={2}>
      <Grid item xs={6}>
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor="cancellation-reason-select">
            {t('Common:CANCELLATION_REASON')}
          </InputLabel>
          <PuiSelect
            field={cancellationReason}
            items={appointmentCancellationReasons}
            onChange={Utils.handleFormSelectInput(onSelectReason)}
          />
        </FormControl>
      </Grid>
      <Grid item className={classes.checkbox} xs={6}>
        <PuiCheckbox
          checked={waiveLateCancellationFee.value}
          label={t('Common:WAIVE_LATE_CANCELLATION_FEE')}
          onChange={onCheckBox}
        />
      </Grid>
    </Grid>
  )
}
