import moment from 'moment'
import { Nil } from '@pbt/pbt-ui-components'

import { isWithinTimeInterval } from './time'

const twentyFourHoursInMinutes = 24 * 60

export const getIsOutsideCancellationWindow = (
  appointmentStartTime: string | Nil,
  appointmentEndTime?: string | Nil,
) =>
  isWithinTimeInterval(
    appointmentStartTime,
    moment(),
    twentyFourHoursInMinutes,
  ) || moment().isBetween(appointmentStartTime, appointmentEndTime)
