import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import * as R from 'ramda'
import { Nil, WellnessPlanVersion } from '@pbt/pbt-ui-components'

import { WellnessPlanLevels } from '~/constants/wellnessPlansConstants'
import i18n from '~/locales/i18n'

import WellnessPlanBasics from './steps/plan-basics/WellnessPlanBasics'
import WellnessPlanChargeSetUp from './steps/plan-customization/WellnessPlanChargeSetUp'
import WellnessPlanLevelBase from './steps/plan-customization/WellnessPlanLevelBase'
import { AutoRenewPlanLinking } from './steps/plan-linking/AutoRenewPlanLinking'
import WellnessPlanFinalReview from './steps/plan-review/WellnessPlanFinalReview'
import WellnessPlanIntermediateReview from './steps/plan-review/WellnessPlanIntermediateReview'
import WellnessPlanBranding from './steps/WellnessPlanBranding'

export enum Steps {
  BRANDING = 'BRANDING',
  PLAN_BASICS = 'PLAN_BASICS',
  PLAN_REVIEW = 'PLAN_REVIEW',
  BASE = 'BASE',
  CHARGE_SET_UP = 'CHARGE_SET_UP',
  FINAL_REVIEW = 'FINAL_REVIEW',
}

export enum PostActivateSteps {
  PLAN_LINKING = 'PLAN_LINKING',
}

// The steps order must leave the same
export const StepsList = [
  Steps.BRANDING,
  Steps.PLAN_BASICS,
  Steps.PLAN_REVIEW,
  Steps.BASE,
  Steps.CHARGE_SET_UP,
  Steps.FINAL_REVIEW,
]

const LevelSteps = [Steps.BASE, Steps.CHARGE_SET_UP, Steps.FINAL_REVIEW]

export const StepLabelsMap = {
  [Steps.BRANDING]: i18n.t('WellnessPlans:STEPS.BRANDING'),
  [Steps.PLAN_BASICS]: i18n.t('WellnessPlans:STEPS.PLAN_BASICS'),
  [Steps.PLAN_REVIEW]: i18n.t('WellnessPlans:STEPS.PLAN_REVIEW'),
  [Steps.BASE]: i18n.t('WellnessPlans:STEPS.BASE'),
  [Steps.CHARGE_SET_UP]: i18n.t('WellnessPlans:STEPS.CHARGE_SET_UP'),
  [Steps.FINAL_REVIEW]: i18n.t('WellnessPlans:STEPS.FINAL_REVIEW'),
  [PostActivateSteps.PLAN_LINKING]: i18n.t(
    'WellnessPlans:STEPS.AUTO_RENEW_PLAN_LINKING',
  ),
}

export interface CreateWellnessPlanVersionPageHandle extends HTMLDivElement {
  get: () => WellnessPlanVersion
  onBack: () => void
  onProceed: () => boolean
  validate: () => boolean
}

export const usePlanStepManager = (
  wellnessPlanVersionId: string | Nil,
  wellnessPlanVersionCandidate: WellnessPlanVersion | Nil,
  stepComponentRef: React.RefObject<CreateWellnessPlanVersionPageHandle>,
  tryUpdateVersion: () => void,
  validate: () => boolean,
) => {
  const { t } = useTranslation(['Common', 'WellnessPlans'])

  const [activeStep, setActiveStep] = useState<Steps | null>(Steps.BRANDING)
  const [validSteps, setValidSteps] = useState(
    wellnessPlanVersionId ? StepsList : [],
  )
  const [activePostActivateStep, setActivePostActivateStep] =
    useState<PostActivateSteps>()
  const [isPostActivateStepValid, setIsPostActivateStepValid] = useState(false)

  const currentStep =
    ((activeStep || activePostActivateStep) as Steps) || PostActivateSteps

  const goToPostActivateStep = () => {
    setActiveStep(null)
    setActivePostActivateStep(PostActivateSteps.PLAN_LINKING)
  }

  const LevelStepsDisabledMap = {
    [Steps.BASE]:
      wellnessPlanVersionCandidate?.basePlanHidden ||
      Boolean(activePostActivateStep),
    [Steps.BRANDING]: Boolean(activePostActivateStep),
    [Steps.CHARGE_SET_UP]:
      Boolean(activePostActivateStep) ||
      !wellnessPlanVersionCandidate?.plans?.some(
        (plan) => plan.level !== WellnessPlanLevels.BASE,
      ),
    [Steps.FINAL_REVIEW]: Boolean(activePostActivateStep),
    [Steps.PLAN_BASICS]: Boolean(activePostActivateStep),
    [Steps.PLAN_REVIEW]: Boolean(activePostActivateStep),
    [PostActivateSteps.PLAN_LINKING]: !activePostActivateStep,
  }

  const LevelStepsInvalidMap = {
    [Steps.BASE]: !R.includes(Steps.BASE, validSteps),
    [Steps.BRANDING]: undefined,
    [Steps.CHARGE_SET_UP]: !R.includes(Steps.CHARGE_SET_UP, validSteps),
    [Steps.FINAL_REVIEW]: undefined,
    [Steps.PLAN_BASICS]: undefined,
    [Steps.PLAN_REVIEW]: undefined,
  }

  const getFirstInvalidChildStep = (step: Steps) => {
    const stepIndex = LevelSteps.indexOf(step)
    const previousSteps = LevelSteps.slice(0, stepIndex)
    return previousSteps.find(
      (item) => LevelStepsInvalidMap[item] && !LevelStepsDisabledMap[item],
    )
  }

  const getStepDisabledByValidation = (step: Steps) => {
    const stepIndex = LevelSteps.indexOf(step)
    const previousSteps = LevelSteps.slice(0, stepIndex)
    const hasPreviousDisabledByValidationSteps = previousSteps.some(
      getStepDisabledByValidation,
    )
    const hasPreviousInvalidSteps = Boolean(getFirstInvalidChildStep(step))

    return (
      !LevelStepsDisabledMap[step] &&
      (hasPreviousDisabledByValidationSteps || hasPreviousInvalidSteps)
    )
  }

  const getTooltipText = (step: Steps) => {
    if (LevelStepsDisabledMap[step]) {
      if (activePostActivateStep) {
        return (
          <Box sx={{ maxWidth: 240 }}>
            {t('WellnessPlans:VERIFY_AUTO_RENEWING_PLANS.LINK_PLANS_AND_SAVE')}
          </Box>
        )
      }
      return t('Common:SOMETHING_IS_DISABLED', {
        something: StepLabelsMap[step],
      })
    }

    const firstInvalidStep = getFirstInvalidChildStep(step)

    return firstInvalidStep
      ? t('Common:PLEASE_COMPLETE_SOMETHING_SETUP', {
          something: StepLabelsMap[firstInvalidStep],
        })
      : undefined
  }

  const StepComponents = {
    [Steps.BRANDING]: {
      label: StepLabelsMap[Steps.BRANDING],
      disabled: LevelStepsDisabledMap[Steps.BRANDING],
      tooltipText: getTooltipText(Steps.BRANDING),
      component: WellnessPlanBranding,
    },
    [Steps.PLAN_BASICS]: {
      label: StepLabelsMap[Steps.PLAN_BASICS],
      disabled: LevelStepsDisabledMap[Steps.PLAN_BASICS],
      tooltipText: getTooltipText(Steps.PLAN_BASICS),
      component: WellnessPlanBasics,
    },
    [Steps.PLAN_REVIEW]: {
      label: StepLabelsMap[Steps.PLAN_REVIEW],
      disabled: LevelStepsDisabledMap[Steps.PLAN_REVIEW],
      tooltipText: getTooltipText(Steps.PLAN_REVIEW),
      component: WellnessPlanIntermediateReview,
    },
    [Steps.BASE]: {
      label: StepLabelsMap[Steps.BASE],
      disabled: LevelStepsDisabledMap[Steps.BASE],
      tooltipText: getTooltipText(Steps.BASE),
      component: WellnessPlanLevelBase,
    },
    [Steps.CHARGE_SET_UP]: {
      label: StepLabelsMap[Steps.CHARGE_SET_UP],
      disabled: LevelStepsDisabledMap[Steps.CHARGE_SET_UP],
      disabledByValidation: getStepDisabledByValidation(Steps.CHARGE_SET_UP),
      tooltipText: getTooltipText(Steps.CHARGE_SET_UP),
      component: WellnessPlanChargeSetUp,
    },
    [Steps.FINAL_REVIEW]: {
      label: StepLabelsMap[Steps.FINAL_REVIEW],
      disabled: LevelStepsDisabledMap[Steps.FINAL_REVIEW],
      disabledByValidation: getStepDisabledByValidation(Steps.FINAL_REVIEW),
      tooltipText: getTooltipText(Steps.FINAL_REVIEW),
      component: WellnessPlanFinalReview,
    },
    [PostActivateSteps.PLAN_LINKING]: {
      label: StepLabelsMap[PostActivateSteps.PLAN_LINKING],
      disabled: LevelStepsDisabledMap[PostActivateSteps.PLAN_LINKING],
      disabledByValidation: false,
      component: AutoRenewPlanLinking,
    },
  }

  const ActiveStepsList = StepsList.filter(
    (step) => !StepComponents[step].disabled,
  )

  const currentActiveStepIndex = ActiveStepsList.indexOf(currentStep)

  const StepComponent = StepComponents[currentStep].component

  const currentStepIndex = StepsList.indexOf(currentStep)
  const hasPrevStep = currentStepIndex > 0
  const hasNextStep = currentStepIndex < StepsList.length - 1

  const onStepValidStateChange = (isValid: boolean) => {
    if (activePostActivateStep) {
      setIsPostActivateStepValid(isValid)
    } else if (isValid) {
      setValidSteps(R.uniq(validSteps.concat(currentStep)))
    } else {
      setValidSteps(R.without([activeStep], validSteps))
    }
  }

  const goToPrevStep = () => {
    tryUpdateVersion()

    const canGoBack = stepComponentRef.current?.onBack?.() ?? true

    if (canGoBack) {
      setActiveStep(ActiveStepsList[currentActiveStepIndex - 1])
    }
  }

  const goToNextStep = () => {
    if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(ActiveStepsList[currentActiveStepIndex + 1])
      }
    }
  }

  const onStepChange = (newStep: string) => {
    const newStepIndex = ActiveStepsList.indexOf(newStep as Steps)
    const shouldValidate = newStepIndex > currentActiveStepIndex

    if (!shouldValidate) {
      tryUpdateVersion()

      setActiveStep(newStep as Steps)
    } else if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(newStep as Steps)
      }
    }
  }

  const onStepperLeftArrowClick = () => {
    const newStepIndex = Math.max(currentActiveStepIndex - 1, 0)
    const newStep = ActiveStepsList[newStepIndex]

    const canGoBack = stepComponentRef.current?.onBack?.() ?? true

    tryUpdateVersion()

    if (canGoBack) {
      setActiveStep(newStep)
    }
  }

  const onStepperRightArrowClick = () => {
    const newStepIndex = Math.min(
      currentActiveStepIndex + 1,
      ActiveStepsList.length - 1,
    )
    const newStep = ActiveStepsList[newStepIndex]

    if (validate()) {
      const canProceed = stepComponentRef.current?.onProceed?.() ?? true

      tryUpdateVersion()

      if (canProceed) {
        setActiveStep(newStep)
      }
    }
  }

  return {
    currentStep,
    activePostActivateStep,
    isPostActivateStepValid,
    goToPostActivateStep,
    StepComponents,
    StepComponent,
    hasPrevStep,
    hasNextStep,
    onStepValidStateChange,
    goToPrevStep,
    goToNextStep,
    onStepChange,
    onStepperLeftArrowClick,
    onStepperRightArrowClick,
  }
}
