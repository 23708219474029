import {
  Constant,
  Nil,
  Responsibility,
  Role,
  User,
} from '@pbt/pbt-ui-components'

import { BusinessAppointmentType } from './businessAppointmentType'
import { Conversation } from './conversations'
import { Invoice } from './finance'
import { ScheduleSlot } from './scheduler'
import { Soap, SoapForm } from './soap'
import { WhiteboardSlot } from './whiteboard'

export type TimetableEventPersonRole = {
  occupyTimeslot: boolean
  person: User
  personId: string
  role: Role
  roleId: string
}

export type TimetableEventPersonResponsibility = {
  occupyTimeslot: boolean
  person: User
  personId: string
  responsibility: Responsibility
  responsibilityId: string
}

export type WritableTimetableEventPersonRole = {
  person: string
  role: string
}

export type WritableTimetableEventPersonResponsibility = {
  person: string
  responsibility: string
}

export type ReleasePeriodSpecifier = 'H' | 'M'
export type ReleasePeriod = `PT${number}${ReleasePeriodSpecifier}`

export type TimetableEvent = {
  actualEndDatetime?: string | Nil
  actualStartDatetime?: string | Nil
  assigned?: boolean
  assignedSpace?: string | Nil
  autocharged?: boolean
  businessAppointmentType?:
    | (Partial<BusinessAppointmentType> & { id: string })
    | Nil
  businessAppointmentTypeRestrictions?: BusinessAppointmentType[]
  businessId?: string | Nil
  bypassValidation?: boolean
  cancellationReason?: string
  cancellationReasonId?: string
  client?: string | Nil
  clientId?: string
  clientInstructions?: string
  conversation?: Conversation
  conversationId?: string
  createdBy?: string | Nil
  creationDate?: string | Nil
  dialIn?: string | Nil
  documentInstances?: SoapForm[]
  estimates?: string[]
  externalPaymentMethodId?: string | Nil
  id: string
  includeZoomLink?: boolean
  internalNotes?: string | Nil
  invoice?: Pick<Invoice, 'id' | 'posted'> | Nil
  invoiceId?: string | Nil
  isNew?: boolean
  meetingLink?: string | Nil
  meetingNotes?: string | Nil
  missingPaymentMethodReasonId?: string | Nil
  modificationDate?: string | Nil
  modifiedBy?: string | Nil
  name?: string
  notes?: string
  otherMissingPaymentMethodReason?: string | Nil
  parentId?: string | Nil
  patient?: string | Nil
  patientId?: string | Nil
  penaltyFeeWaived?: boolean | Nil
  personId?: string
  personResponsibilities?: TimetableEventPersonResponsibility[] | Nil
  personRoles?: TimetableEventPersonRole[] | Nil
  recurrenceEndDate?: string | Nil
  recurrenceParams?:
    | {
        days: string[]
      }
    | Nil
  recurrenceStartDate?: string | Nil
  releaseTimeOffset?: ReleasePeriod | Nil
  scheduledEndDatetime?: string | Nil
  scheduledStartDatetime?: string | Nil
  soapId?: string | Nil
  soaps?: Soap[]
  space?: { id: string; name: string } | Nil
  startDate?: string
  state?: Constant
  stateId?: string | Nil
  subTypeId?: string
  telehealthProviderId?: string | Nil
  type?: Constant
  typeId?: string | Nil
  wplanMembersOnly?: boolean
}

export type WritableTimetableEvent = Omit<
  TimetableEvent,
  'personRoles' | 'state' | 'assignedSpace' | 'type' | 'personResponsibilities'
> & {
  personResponsibilities?: WritableTimetableEventPersonResponsibility[]
  personRoles?: WritableTimetableEventPersonRole[]
  spaceId: string
  state?: string
  type?: string
}

export interface ConferencingData {
  dialIn: string
  includeZoomLink: boolean
  meetingLink: string
  meetingNotes: string
  zoomUserId?: string
}

export type EventProduct = {
  details: string[]
  disabled: boolean
  notes: string | Nil
  qty: string
  retailOrderLineItem?: {
    autoshipFrequency: number | Nil
    autoshipUnitId: string | Nil
  }
}

export type TimetableFilter<T> = {
  id: string
  label: string
  matcher: (candidate: T) => boolean
  selected?: boolean
  type?: string
}

export type TimetableSlot = ScheduleSlot | WhiteboardSlot

export enum DocumentSource {
  APPOINTMENT = 'APPOINTMENT',
  SOAP = 'SOAP',
}
