import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uuid } from 'uuid'

import { AppointmentState } from '~/constants/appointmentStates'
import SnackNotificationType from '~/constants/SnackNotificationType'
import { addUiNotification } from '~/store/duck/uiNotifications'
import { getPatientName } from '~/store/reducers/patients'
import { getPatchedAppointmentData } from '~/store/reducers/timetable'
import { useAppointmentStateId } from '~/utils/useEventType'

export const useAppointmentCancellationNotification = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)
  const patchedAppointmentData = useSelector(getPatchedAppointmentData)
  const patientName = useSelector(
    getPatientName(patchedAppointmentData?.patientId),
  )

  useEffect(() => {
    if (patchedAppointmentData?.stateId === cancelledStateId && patientName) {
      dispatch(
        addUiNotification({
          id: uuid(),
          type: SnackNotificationType.APPOINTMENT_CANCELLATION,
          message: t('Dialogs:APPOINTMENT_CANCELLATION_DIALOG.TOAST.MESSAGE', {
            patientName,
            appointmentTypeName: patchedAppointmentData?.type?.name,
          }),
        }),
      )
    }
  }, [patchedAppointmentData, patientName, cancelledStateId])
}
