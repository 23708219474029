import { ApiError, BlobWithName, Nil } from '@pbt/pbt-ui-components'

import {
  AttachmentHistoryDocumentItem,
  AttachmentHistoryItem,
  DiagnoseFilter,
  DiagnoseHistoryItem,
  FilesWithDetails,
  MedicalHistoryDiagnose,
  MedicalHistoryVaccine,
  NoteHistoryItem,
  UnsavedDiagnoseHistoryItem,
  UnsavedVaccineHistoryItem,
  VaccineHistoryItem,
} from '~/types'

import {
  CLEAR_MEDICAL_HISTORY_DIAGNOSES,
  CLEAR_PMR_TIMER,
  CREATE_PMR_TIMER,
  DELETE_ATTACHMENT_HISTORY,
  DELETE_ATTACHMENT_HISTORY_FAILURE,
  DELETE_ATTACHMENT_HISTORY_SUCCESS,
  DELETE_DIAGNOSIS_HISTORY,
  DELETE_DIAGNOSIS_HISTORY_FAILURE,
  DELETE_DIAGNOSIS_HISTORY_SUCCESS,
  DELETE_NOTE_HISTORY,
  DELETE_NOTE_HISTORY_FAILURE,
  DELETE_NOTE_HISTORY_SUCCESS,
  DELETE_VACCINE_HISTORY,
  DELETE_VACCINE_HISTORY_FAILURE,
  DELETE_VACCINE_HISTORY_SUCCESS,
  EDIT_DIAGNOSIS_HISTORY,
  EDIT_DIAGNOSIS_HISTORY_FAILURE,
  EDIT_DIAGNOSIS_HISTORY_SUCCESS,
  EDIT_NOTE_HISTORY,
  EDIT_NOTE_HISTORY_FAILURE,
  EDIT_NOTE_HISTORY_SUCCESS,
  EDIT_VACCINE_HISTORY,
  EDIT_VACCINE_HISTORY_FAILURE,
  EDIT_VACCINE_HISTORY_SUCCESS,
  FETCH_ATTACHMENT_HISTORY,
  FETCH_ATTACHMENT_HISTORY_FAILURE,
  FETCH_ATTACHMENT_HISTORY_SUCCESS,
  FETCH_DIAGNOSES,
  FETCH_DIAGNOSES_FAILURE,
  FETCH_DIAGNOSES_FILTERS,
  FETCH_DIAGNOSES_FILTERS_FAILURE,
  FETCH_DIAGNOSES_FILTERS_SUCCESS,
  FETCH_DIAGNOSES_SUCCESS,
  FETCH_VACCINES,
  FETCH_VACCINES_FAILURE,
  FETCH_VACCINES_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE,
  SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_FAILURE,
  SAVE_ATTACHMENT_HISTORY_SUCCESS,
  SAVE_ATTACHMENT_HISTORY_V2,
  SAVE_DIAGNOSIS_HISTORY,
  SAVE_DIAGNOSIS_HISTORY_FAILURE,
  SAVE_DIAGNOSIS_HISTORY_SUCCESS,
  SAVE_MULTI_ATTACHMENT_HISTORY,
  SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE,
  SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS,
  SAVE_NOTE_HISTORY,
  SAVE_NOTE_HISTORY_FAILURE,
  SAVE_NOTE_HISTORY_SUCCESS,
  SAVE_PAST_MEDICAL_RECORD,
  SAVE_PAST_MEDICAL_RECORD_FAILURE,
  SAVE_PAST_MEDICAL_RECORD_SUCCESS,
  SAVE_VACCINE_HISTORY,
  SAVE_VACCINE_HISTORY_FAILURE,
  SAVE_VACCINE_HISTORY_SUCCESS,
} from './types/medicalHistory'

export const fetchVaccines = (patientId: string) => ({
  type: FETCH_VACCINES,
  patientId,
})
export const fetchVaccinesSuccess = (vaccines: MedicalHistoryVaccine[]) => ({
  type: FETCH_VACCINES_SUCCESS,
  vaccines,
})
export const fetchVaccinesFailure = (error: ApiError) => ({
  type: FETCH_VACCINES_FAILURE,
  error,
})

export const saveVaccineHistory = (
  items: UnsavedVaccineHistoryItem[],
  patientId: string,
  clientId?: string | Nil,
) => ({
  type: SAVE_VACCINE_HISTORY,
  items,
  patientId,
  clientId,
})
export const saveVaccineHistorySuccess = () => ({
  type: SAVE_VACCINE_HISTORY_SUCCESS,
})
export const saveVaccineHistoryFailure = (error: ApiError) => ({
  type: SAVE_VACCINE_HISTORY_FAILURE,
  error,
})

export const editVaccineHistory = (
  items: VaccineHistoryItem[],
  patientId: string,
) => ({
  type: EDIT_VACCINE_HISTORY,
  items,
  patientId,
})
export const editVaccineHistorySuccess = () => ({
  type: EDIT_VACCINE_HISTORY_SUCCESS,
})
export const editVaccineHistoryFailure = (error: ApiError) => ({
  type: EDIT_VACCINE_HISTORY_FAILURE,
  error,
})

export const deleteVaccineHistory = (itemId: string, patientId: string) => ({
  type: DELETE_VACCINE_HISTORY,
  itemId,
  patientId,
})
export const deleteVaccineHistorySuccess = () => ({
  type: DELETE_VACCINE_HISTORY_SUCCESS,
})
export const deleteVaccineHistoryFailure = (error: ApiError) => ({
  type: DELETE_VACCINE_HISTORY_FAILURE,
  error,
})

export const fetchDiagnosesFilters = (patientId: string) => ({
  type: FETCH_DIAGNOSES_FILTERS,
  patientId,
})
export const fetchDiagnosesFiltersSuccess = (filters: DiagnoseFilter[]) => ({
  type: FETCH_DIAGNOSES_FILTERS_SUCCESS,
  filters,
})
export const fetchDiagnosesFiltersFailure = (error: ApiError) => ({
  type: FETCH_DIAGNOSES_FILTERS_FAILURE,
  error,
})

export const fetchDiagnoses = (
  patientId: string,
  categories: string[],
  type: string,
) => ({
  type: FETCH_DIAGNOSES,
  patientId,
  categories,
  entityType: type,
})
export const fetchDiagnosesSuccess = (diagnoses: MedicalHistoryDiagnose[]) => ({
  type: FETCH_DIAGNOSES_SUCCESS,
  diagnoses,
})
export const fetchDiagnosesFailure = (error: ApiError) => ({
  type: FETCH_DIAGNOSES_FAILURE,
  error,
})

export const saveDiagnosisHistory = (
  items: UnsavedDiagnoseHistoryItem[],
  patientId: string,
  clientId: string | Nil,
) => ({
  type: SAVE_DIAGNOSIS_HISTORY,
  items,
  patientId,
  clientId,
})
export const saveDiagnosisHistorySuccess = () => ({
  type: SAVE_DIAGNOSIS_HISTORY_SUCCESS,
})
export const saveDiagnosisHistoryFailure = (error: ApiError) => ({
  type: SAVE_DIAGNOSIS_HISTORY_FAILURE,
  error,
})

export const editDiagnosisHistory = (
  items: DiagnoseHistoryItem[],
  patientId: string,
) => ({
  type: EDIT_DIAGNOSIS_HISTORY,
  items,
  patientId,
})
export const editDiagnosisHistorySuccess = () => ({
  type: EDIT_DIAGNOSIS_HISTORY_SUCCESS,
})
export const editDiagnosisHistoryFailure = (error: ApiError) => ({
  type: EDIT_DIAGNOSIS_HISTORY_FAILURE,
  error,
})

export const deleteDiagnosisHistory = (itemId: string, patientId: string) => ({
  type: DELETE_DIAGNOSIS_HISTORY,
  itemId,
  patientId,
})
export const deleteDiagnosisHistorySuccess = () => ({
  type: DELETE_DIAGNOSIS_HISTORY_SUCCESS,
})
export const deleteDiagnosisHistoryFailure = (error: ApiError) => ({
  type: DELETE_DIAGNOSIS_HISTORY_FAILURE,
  error,
})

export const saveNoteHistory = (
  items: NoteHistoryItem[],
  patientId: string,
  clientId: string | Nil,
) => ({
  type: SAVE_NOTE_HISTORY,
  items,
  patientId,
  clientId,
})
export const saveNoteHistorySuccess = () => ({
  type: SAVE_NOTE_HISTORY_SUCCESS,
})
export const saveNoteHistoryFailure = (error: ApiError) => ({
  type: SAVE_NOTE_HISTORY_FAILURE,
  error,
})

export const editNoteHistory = (
  items: NoteHistoryItem[],
  patientId: string,
) => ({
  type: EDIT_NOTE_HISTORY,
  items,
  patientId,
})
export const editNoteHistorySuccess = () => ({
  type: EDIT_NOTE_HISTORY_SUCCESS,
})
export const editNoteHistoryFailure = (error: ApiError) => ({
  type: EDIT_NOTE_HISTORY_FAILURE,
  error,
})

export const deleteNoteHistory = (itemId: string, patientId: string) => ({
  type: DELETE_NOTE_HISTORY,
  itemId,
  patientId,
})
export const deleteNoteHistorySuccess = () => ({
  type: DELETE_NOTE_HISTORY_SUCCESS,
})
export const deleteNoteHistoryFailure = (error: ApiError) => ({
  type: DELETE_NOTE_HISTORY_FAILURE,
  error,
})

export const fetchAttachmentHistory = (patientId: string) => ({
  type: FETCH_ATTACHMENT_HISTORY,
  patientId,
})
export const fetchAttachmentHistorySuccess = (
  attachments: AttachmentHistoryItem[],
) => ({
  type: FETCH_ATTACHMENT_HISTORY_SUCCESS,
  attachments,
})
export const fetchAttachmentHistoryFailure = (error: ApiError) => ({
  type: FETCH_ATTACHMENT_HISTORY_FAILURE,
  error,
})

export const saveAttachmentHistoryV2 = (
  blob: BlobWithName,
  item: AttachmentHistoryItem,
  patientId: string,
  clientId?: string | Nil,
) => ({ type: SAVE_ATTACHMENT_HISTORY_V2, blob, item, patientId, clientId })
export const saveAttachmentHistorySuccess = () => ({
  type: SAVE_ATTACHMENT_HISTORY_SUCCESS,
})
export const saveAttachmentHistoryFailure = (error: ApiError) => ({
  type: SAVE_ATTACHMENT_HISTORY_FAILURE,
  error,
})

export const savePastMedicalRecord = (
  files: FilesWithDetails,
  patientId: string,
  clientId?: string | Nil,
) => ({ type: SAVE_PAST_MEDICAL_RECORD, files, patientId, clientId })
export const savePastMedicalRecordSuccess = () => ({
  type: SAVE_PAST_MEDICAL_RECORD_SUCCESS,
})
export const savePastMedicalRecordFailure = (error: ApiError) => ({
  type: SAVE_PAST_MEDICAL_RECORD_FAILURE,
  error,
})

export const createPMRTimer = (patientId: string) => ({
  type: CREATE_PMR_TIMER,
  patientId,
})
export const clearPMRTimer = () => ({
  type: CLEAR_PMR_TIMER,
})

export const saveMultiAttachmentHistory = (
  attachmentGroup: FilesWithDetails,
  patientId: string,
  clientId?: string | Nil,
) => ({
  type: SAVE_MULTI_ATTACHMENT_HISTORY,
  attachmentGroup,
  patientId,
  clientId,
})
export const saveMultiAttachmentHistorySuccess = () => ({
  type: SAVE_MULTI_ATTACHMENT_HISTORY_SUCCESS,
})
export const saveMultiAttachmentHistoryFailure = (error: ApiError) => ({
  type: SAVE_MULTI_ATTACHMENT_HISTORY_FAILURE,
  error,
})

export const saveAttachmentHistoryDocuments = (
  items: Partial<AttachmentHistoryDocumentItem>[],
  patientId: string,
  clientId?: string | Nil,
) => ({
  type: SAVE_ATTACHMENT_HISTORY_DOCUMENTS,
  items,
  clientId,
  patientId,
})
export const saveAttachmentHistoryDocumentsSuccess = () => ({
  type: SAVE_ATTACHMENT_HISTORY_DOCUMENTS_SUCCESS,
})
export const saveAttachmentHistoryDocumentsFailure = (error: ApiError) => ({
  type: SAVE_ATTACHMENT_HISTORY_DOCUMENTS_FAILURE,
  error,
})

export const deleteAttachmentHistory = (
  attachmentId: string,
  patientId: string,
) => ({
  type: DELETE_ATTACHMENT_HISTORY,
  attachmentId,
  patientId,
})
export const deleteAttachmentHistorySuccess = () => ({
  type: DELETE_ATTACHMENT_HISTORY_SUCCESS,
})
export const deleteAttachmentHistoryFailure = (error: ApiError) => ({
  type: DELETE_ATTACHMENT_HISTORY_FAILURE,
  error,
})

export const clearMedicalHistoryDiagnoses = () => ({
  type: CLEAR_MEDICAL_HISTORY_DIAGNOSES,
})
