import { Nil } from '@pbt/pbt-ui-components'

import {
  AttachmentHistoryItem,
  NoteHistoryItem,
  UnsavedAttachmentHistoryItem,
  UnsavedDiagnoseHistoryItem,
  UnsavedVaccineHistoryItem,
} from '~/types'

import { getAPIPath, request } from './core'
import { requestMutation } from './graphql/graphqlCore'
import { CREATE_ATTACHMENT_HISTORY_GROUP } from './graphql/mutations/medicalHistory'

export const fetchVaccines = (businessId: string, patientId: string) =>
  request(
    getAPIPath('vaccination'),
    { method: 'GET', params: { businessId, patientId } },
    true,
  )

export const saveVaccineHistory = (
  businessId: string,
  items: UnsavedVaccineHistoryItem[],
  patientId: string,
  clientId?: string | Nil,
) =>
  request(
    getAPIPath(`vaccination/history/${patientId}/batch`),
    {
      method: 'POST',
      data: items,
      params: { businessId, patientId, clientId },
    },
    true,
  )

export const deleteVaccineHistory = (
  businessId: string,
  itemId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`vaccination/history/${patientId}/${itemId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchDiagnosesFilters = (businessId: string, patientId: string) =>
  request(
    getAPIPath('diagnosis/filters'),
    { method: 'GET', params: { businessId, patientId } },
    true,
  )

export const fetchDiagnoses = (
  businessId: string,
  patientId: string,
  categories: string,
  type: string,
) =>
  request(
    getAPIPath('diagnosis'),
    { method: 'GET', params: { businessId, patientId, categories, type } },
    true,
  )

export const saveDiagnosisHistory = (
  businessId: string,
  items: UnsavedDiagnoseHistoryItem[],
  patientId: string,
  clientId?: string | Nil,
) =>
  request(
    getAPIPath(`diagnosis/history/${patientId}/batch`),
    {
      method: 'POST',
      data: items,
      params: { businessId, patientId, clientId },
    },
    true,
  )

export const deleteDiagnosisHistory = (
  businessId: string,
  itemId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`diagnosis/history/${patientId}/${itemId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const saveNoteHistory = (
  businessId: string,
  items: NoteHistoryItem[],
  patientId: string,
  clientId?: string | Nil,
) =>
  request(
    getAPIPath(`notes/history/${patientId}/batch`),
    {
      method: 'POST',
      data: items,
      params: { businessId, patientId, clientId },
    },
    true,
  )

export const deleteNoteHistory = (
  businessId: string,
  itemId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`notes/history/${patientId}/${itemId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const fetchAttachmentHistory = (businessId: string, patientId: string) =>
  request(
    getAPIPath(`attachments/history/${patientId}`),
    { method: 'GET', params: { businessId } },
    true,
  )

export const saveAttachmentHistoryDocuments = (
  businessId: string,
  items: UnsavedAttachmentHistoryItem[],
  patientId: string,
  clientId: string | Nil,
) =>
  request(
    getAPIPath(`attachments/history/${patientId}/documents`),
    {
      method: 'POST',
      data: items,
      params: { businessId, patientId, clientId },
    },
    true,
  )

export const saveAttachmentHistoryV2 = (
  businessId: string,
  item: AttachmentHistoryItem,
  fileUrl: string,
  patientId: string,
  clientId: string | Nil,
) =>
  request(
    getAPIPath(`v2/attachments/history/${patientId}`),
    {
      method: 'POST',
      data: { ...item, patientId, clientId, businessId, fileUrl },
      params: { businessId },
    },
    true,
  )

export const deleteAttachmentHistory = (
  businessId: string,
  attachmentId: string,
  patientId: string,
) =>
  request(
    getAPIPath(`attachments/history/${patientId}/${attachmentId}`),
    { method: 'DELETE', params: { businessId } },
    true,
  )

export const saveMultiAttachmentHistory = requestMutation({
  mutation: CREATE_ATTACHMENT_HISTORY_GROUP,
  variablesHandler: (businessId, patientId, attachmentHistoryGroup) => ({
    patientId,
    attachmentHistoryGroup,
  }),
})
