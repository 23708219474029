import { useSelector } from 'react-redux'
import { WellnessPlan } from '@pbt/pbt-ui-components'

import FeatureToggle from '~/constants/featureToggle'
import { getFeatureToggle } from '~/store/reducers/constants'
import { getWellnessPlanVersion } from '~/store/reducers/wellnessPlans'

export const useAutoRenewPlanInfo = (wellnessPlan: WellnessPlan) => {
  const isWpAutoRenewalLinkingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.WP_LINKING_AUTO_RENEWAL),
  )

  const renewVersion = useSelector(
    // REMOVE in CVC-13486
    // @ts-ignore
    getWellnessPlanVersion(wellnessPlan.renewVersionId),
  )
  const renewPlan = renewVersion?.plans.find(
    // REMOVE in CVC-13486
    // @ts-ignore
    (plan: WellnessPlan) => plan.id === wellnessPlan.renewPlanId,
  )

  const showAutoRenewPlanInfo =
    isWpAutoRenewalLinkingEnabled &&
    renewVersion?.versionNumber &&
    renewPlan?.name

  return {
    showAutoRenewPlanInfo,
    isWpAutoRenewalLinkingEnabled,
    renewVersion,
    renewPlan,
  }
}
