import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import { v4 as uuid } from 'uuid'
import {
  AlertIconType,
  BasePuiDialogProps,
  CustomFieldValidatorState,
  LanguageUtils,
  PuiAlert,
  PuiTextField,
  useFields,
  Utils,
  Validators,
} from '@pbt/pbt-ui-components'

import EnumRadioGroup from '~/components/common/inputs/EnumRadioGroup'
import NotesTemplateInput from '~/components/dashboard/template-inputs/NotesTemplateInput'
import FeatureToggle from '~/constants/featureToggle'
import {
  getFeatureToggle,
  getInternalCancellationReasons,
} from '~/store/reducers/constants'

import { AppointmentDepositAlert } from './AppointmentDepositAlert'
import AppointmentCancellationWaiveFeeAlert from './reminders/AppointmentCancellationWaiveFeeAlert'

const validateOtherField = (
  otherReasonId: string,
  {
    state: { appointmentCancellationReason, otherReason },
  }: CustomFieldValidatorState,
) => {
  if (appointmentCancellationReason === otherReasonId)
    return Validators.notEmptyFormField(otherReason)

  return true
}

const useStyles = makeStyles(
  () => ({
    notesTemplateInput: {
      maxHeight: 170,
      overflowY: 'auto',
    },
  }),
  { name: 'Appointment' },
)

const StyledAlert = styled(PuiAlert)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 500,
    maxWidth: 500,
    padding: theme.spacing(1),
  },
}))

export interface AppointmentCancellationReasonDialogProps
  extends BasePuiDialogProps {
  appointmentWithin24Hours?: boolean
  clientId: string
  onCancel?: () => void
  onProceed: (
    appointmentCancellationReasonId: string,
    appointmentCancellationReasonName: string,
    waiveLateCancellationFee: boolean,
    internalNotes: string,
  ) => void
  patientId: string
  showDepositAlert?: boolean
  showNoShowCancellationPenaltyAlert?: boolean
}

export const AppointmentCancellationReasonDialog = ({
  open,
  onProceed,
  onClose,
  onCancel,
  clientId,
  patientId,
  showDepositAlert = false,
  showNoShowCancellationPenaltyAlert = false,
  appointmentWithin24Hours,
  ...rest
}: AppointmentCancellationReasonDialogProps) => {
  const { t } = useTranslation(['Dialogs', 'Common'])
  const classes = useStyles()
  const internalCancellationReasons = useSelector(
    getInternalCancellationReasons,
  )

  const noShowPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )

  const [internalNoteKey, setInternalNoteKey] = useState(uuid())

  const otherReasonId = Utils.findConstantIdByName(
    'Other',
    internalCancellationReasons,
  )

  const { fields, validate } = useFields(
    [
      {
        name: 'appointmentCancellationReason',
        validators: ['required'],
        type: 'select',
      },
      {
        name: 'otherReason',
        validators: [
          {
            validator: (state) => validateOtherField(otherReasonId, state),
            validatorName: 'required',
          },
        ],
      },
      {
        name: 'waiveLateCancellationFee',
        initialValue: Boolean(noShowPenaltyCardOnFileAlertEnabled),
      },
      { name: 'internalNotes', initialValue: '' },
    ],
    false,
  )

  const {
    appointmentCancellationReason,
    otherReason,
    waiveLateCancellationFee,
    internalNotes,
  } = fields

  const setInternalNote = (value: string) => {
    setInternalNoteKey(uuid())
    internalNotes.setValue(value)
  }

  const cancellationReasonRegExp = new RegExp(
    `<p>${t('Common:CANCELLATION_REASON')}:\\s*([^<]+)<\\/p>`,
  )
  const cancellationReasonRegExpExact = new RegExp(
    `(<p>${t('Common:CANCELLATION_REASON')}: [^<]+</p>)`,
  )
  const waiveLateCancellationRegExp = new RegExp(
    `<p>${t('Common:LATE_CANCELLATION_FEE_WAIVED')}<\\/p>`,
  )

  const onWaiveLateCancellationFeeChange = (enabled: boolean) => {
    const prevValue = internalNotes.value
    let newValue = ''

    if (enabled) {
      newValue =
        prevValue === ''
          ? `<p>${t('Common:LATE_CANCELLATION_FEE_WAIVED')}</p>`
          : prevValue.replace(
              cancellationReasonRegExpExact,
              `$1 <p>${t('Common:LATE_CANCELLATION_FEE_WAIVED')}</p>`,
            )
    } else {
      newValue = prevValue.replace(waiveLateCancellationRegExp, '')
    }
    setInternalNote(newValue)
  }

  const onCancellationReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = event?.target?.value
    const reasons = internalCancellationReasons.filter((r) => r.id === value)
    const reason = reasons.length > 0 ? reasons[0] : null

    const prevValue = internalNotes.value
    if (!R.isNil(reason)) {
      const optionalBr = waiveLateCancellationRegExp.test(prevValue)
        ? ''
        : '</br>'
      const newValue = cancellationReasonRegExp.test(prevValue)
        ? prevValue.replace(
            cancellationReasonRegExp,
            `<p>${t('Common:CANCELLATION_REASON')}: ${reason.name}</p>`,
          )
        : `<p>${t('Common:CANCELLATION_REASON')}: ${reason.name}</p>${optionalBr}${prevValue}`
      setInternalNote(newValue)
    }
  }

  useEffect(() => {
    if (noShowPenaltyCardOnFileAlertEnabled) {
      onWaiveLateCancellationFeeChange(true)
    }
  }, [noShowPenaltyCardOnFileAlertEnabled])

  const handleProceed = () => {
    if (!validate()) return

    const reasonName =
      otherReason.value ||
      LanguageUtils.getConstantTranslatedName(
        appointmentCancellationReason.value,
        internalCancellationReasons,
      )

    onProceed(
      appointmentCancellationReason.value,
      reasonName,
      waiveLateCancellationFee.value,
      internalNotes.value,
    )
    if (!onClose) return
    onClose()
  }

  const handleCancel = () => {
    onCancel?.()
    onClose?.()
  }

  return (
    <StyledAlert
      aria-labelledby="appointment-cancellation-reason-dialog"
      cancelButtonText={t('Common:GO_BACK')}
      content={
        <>
          <EnumRadioGroup
            Constant={internalCancellationReasons}
            field={appointmentCancellationReason}
            onChange={onCancellationReasonChange}
          />
          {appointmentCancellationReason.value === otherReasonId && (
            <Box pl={4} width="100%">
              <PuiTextField
                field={otherReason}
                inputProps={{ maxLength: 1500 }}
                label={t(
                  'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.OTHER_REASON_FOR_CANCELLATION',
                )}
              />
            </Box>
          )}
          {showDepositAlert && (
            <AppointmentDepositAlert
              appointmentWithin24Hours={appointmentWithin24Hours}
            />
          )}
          <Grid mb={1} mt={1} width="100%">
            <NotesTemplateInput
              resetStateOnValueChange
              singleLine
              classes={{ richEditRoot: classes.notesTemplateInput }}
              clientId={clientId}
              field={internalNotes}
              key={internalNoteKey}
              minHeight={30}
              patientId={patientId}
              title={t('Common:INTERNAL_NOTES')}
            />
          </Grid>
          {showNoShowCancellationPenaltyAlert && (
            <AppointmentCancellationWaiveFeeAlert
              field={waiveLateCancellationFee}
              onChange={onWaiveLateCancellationFeeChange}
            />
          )}
        </>
      }
      iconType={AlertIconType.WARN}
      message={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.WHY_IS_THE_APPOINTMENT_BEING_CANCELED',
      )}
      okButtonText={t(
        'Dialogs:APPOINTMENT_CANCELLATION_DIALOG.CANCEL_APPOINTMENT',
      )}
      open={open}
      onCancel={handleCancel}
      onClose={handleCancel}
      onOk={handleProceed}
      {...rest}
    />
  )
}
