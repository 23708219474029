/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Autocomplete,
  AutocompleteCloseReason,
  AutocompleteProps,
  Checkbox,
  CircularProgress,
  Grid,
  GridProps,
  IconButton,
  InputAdornment,
  Select,
  Theme,
  useMediaQuery,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import { Property } from 'csstype'
import * as R from 'ramda'
import { useDebounce } from 'use-debounce'
import {
  Defaults,
  Field,
  PuiPopper,
  PuiPopperProps,
  PuiTextField,
  PuiTextFieldProps,
  RoleName,
  Text,
  User,
  Utils,
} from '@pbt/pbt-ui-components'
import ResponsibilityName from '@pbt/pbt-ui-components/src/constants/responsibilityNames'
import { Boop, Toggle } from '@pbt/pbt-ui-components/src/icons'

import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import SecretSearchContext from '~/constants/secretSearchContext'
import i18n from '~/locales/i18n'
import {
  clearSuggestionResults,
  fetchMoreSuggestionResults,
  fetchSuggestionResults,
} from '~/store/actions/search'
import { useMainStaffRoles } from '~/store/hooks/useMainStaffRoles'
import { getFeatureToggle } from '~/store/reducers/constants'
import {
  getClientRoleIdsList,
  getMainStaffRoleIdsList,
  getStaffRoleIdsList,
} from '~/store/reducers/roles'
import {
  getSecretSearchIsReceiving,
  getSuggestedTotalCount,
  getSuggestionResults,
} from '~/store/reducers/search'
import { getUser, getUsersMap } from '~/store/reducers/users'
import { SuggestionResult, TeamFilter } from '~/types'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import useEffectExceptOnMount from '~/utils/useEffectExceptOnMount'

import Avatar from '../Avatar'
import ToggleLabel from './ToggleLabel'

const EMPTY_MEMBER_ID = null

const getEmptyOption = (emptyLabel: string) => ({
  id: EMPTY_MEMBER_ID,
  firstName: emptyLabel,
})

export const PET_PARENT_OPTION_ID = 'PET_PARENT'

const getPetParentOption = (petParent?: User) => ({
  ...petParent,
  id: PET_PARENT_OPTION_ID,
  firstName: petParent
    ? Utils.getPersonString(petParent)
    : i18n.t('Common:PET_PARENT'),
  lastName: '',
})

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    rootEnabled: {
      cursor: 'pointer',
    },
    rootBordered: {
      border: theme.constants.tableBorder,
      borderRadius: 2,
    },
    underline: {
      borderBottom: theme.constants.tableBorder,
    },
    textContainer: {
      borderRight: theme.constants.tableBorder,
      padding: theme.spacing(0, 1),
      height: '100%',
    },
    invalidColor: {
      color: theme.colors.errorColor,
    },
    invalidBorder: {
      borderColor: theme.colors.errorColor,
    },
    expandIcon: {
      color: theme.colors.tabLabel,
    },
  }),
  { name: 'UserSelect' },
)

const usePopperStyles = makeStyles(
  () => ({
    paper: {
      borderRadius: 0,
      margin: 0,
    },
    popper: {
      width: '250px !important',
    },
  }),
  { name: 'UserSelectPopper' },
)

const useAutocompleteStyles = makeStyles(
  (theme) => ({
    multipleOption: {
      paddingTop: 0,
      paddingBottom: 0,
      color: theme.colors.secondaryText,
      fontSize: '1.6rem',
    },
    popperDisablePortal: {
      position: 'relative !important' as Property.Position,
      transform: 'none !important',
    },
    autocompleteRoot: {
      width: '100%',
      borderBottom: theme.constants.tableBorder,
      padding: theme.spacing(0, 2, 1),
    },
    popper: {
      width: '100% !important',
    },
    paper: {
      borderRadius: 0,
      margin: 0,
    },
    listbox: {
      // 40vh - default from MUI, 3 spacing units (search padding (1) + search margin (2)), 4px - misc.
      maxHeight: `calc(40vh - ${theme.spacing(3)} - 4px)`,
    },
  }),
  { name: 'UserSelectAutocomplete' },
)

const useOptionStyles = makeStyles(
  (theme) => ({
    avatar: {
      width: 32,
      height: 32,
      marginRight: theme.spacing(1),
    },
    hiddenAvatar: {
      opacity: 0,
    },
  }),
  { name: 'Option' },
)

const usePlainSelectStyles = makeStyles(
  (theme) => ({
    toggleIcon: {
      right: 0,
      color: theme.colors.toggleIcon,
      position: 'absolute',
      pointerEvents: 'none',
    },
  }),
  { name: 'PlainSelect' },
)

const usePlainTextInputStyles = makeStyles(
  (theme) => ({
    iconButton: {
      padding: 2,
      '&:hover': {
        color: theme.colors.iconHover,
      },
    },
    iconButtonHidden: {
      visibility: 'hidden',
    },
  }),
  { name: 'PlainTextInput' },
)

const useMultipleOptionStyles = makeStyles(
  (theme) => ({
    checkbox: {
      marginRight: theme.spacing(1),
    },
  }),
  { name: 'MultipleOption' },
)

const usePetParentOptionStyles = makeStyles(
  (theme) => ({
    boopIcon: {
      width: '1em',
      height: '1em',
      margin: theme.spacing(0, 1.5, 0, 0.5),
    },
  }),
  { name: 'PetParentOption' },
)

const usePlainOptionStyles = makeStyles(
  () => ({
    boopIcon: {
      width: 16,
      height: 16,
    },
  }),
  { name: 'PlainOption' },
)

interface MultipleOptionProps extends GridProps<'li'> {
  member: User
  selected: boolean
}

const MultipleOption = ({ member, selected, ...rest }: MultipleOptionProps) => {
  const classes = useMultipleOptionStyles()

  return (
    <Grid
      container
      item
      alignItems="center"
      component="li"
      wrap="nowrap"
      {...rest}
    >
      <Checkbox checked={selected} className={classes.checkbox} size="small" />
      {Utils.getPersonString(member)}
    </Grid>
  )
}

interface OptionProps extends GridProps<'li'> {
  assigned?: boolean
  member: User
}

const Option = ({ member, assigned, ...rest }: OptionProps) => {
  const classes = useOptionStyles()
  const { t } = useTranslation('Common')

  return (
    <Grid
      container
      item
      alignItems="center"
      component="li"
      wrap="nowrap"
      {...rest}
    >
      <Avatar
        alt="userpic"
        className={classNames(classes.avatar, {
          [classes.hiddenAvatar]: !member.id,
        })}
        person={member}
        size="small"
      />
      <Grid container item direction="column">
        {assigned && <Text variant="lowAccent2">{t('Common:ASSIGNED')}</Text>}
        <Text>{Utils.getPersonString(member)}</Text>
      </Grid>
    </Grid>
  )
}

interface PetParentOptionProps extends GridProps<'li'> {
  member: User
}

const PetParentOption = ({ member, ...rest }: PetParentOptionProps) => {
  const classes = usePetParentOptionStyles()
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  return (
    <Grid container item alignItems="center" component="li" {...rest}>
      {!isBoopDisablementEnabled && <Boop className={classes.boopIcon} />}
      <Text>{Utils.getPersonString(member)}</Text>
    </Grid>
  )
}

interface PlainOptionProps {
  disabled?: boolean
  emptyLabel?: string
  member?: User
}

const PlainOption = ({ member, emptyLabel, disabled }: PlainOptionProps) => {
  const classes = usePlainOptionStyles()
  const { t } = useTranslation('Common')
  const plainOptionEmptyLabel = emptyLabel || t<string>('Common:UNASSIGNED')
  const isBoopDisablementEnabled = useSelector(
    getFeatureToggle(FeatureToggle.BOOP_DISABLEMENT),
  )

  return (
    <ToggleLabel
      disabled={disabled}
      icon={
        member?.id === PET_PARENT_OPTION_ID && !isBoopDisablementEnabled ? (
          <Boop className={classes.boopIcon} />
        ) : null
      }
      text={member ? Utils.getPersonString(member) : plainOptionEmptyLabel}
    />
  )
}

interface PlainSelectProps {
  disabled?: boolean
  emptyLabel?: string
  member?: User
}

const PlainSelect = ({
  member,
  disabled = true,
  emptyLabel,
}: PlainSelectProps) => {
  const classes = usePlainSelectStyles()
  const { t } = useTranslation('Common')
  const plainSelectEmptyLabel = emptyLabel || t('Common:UNASSIGNED')

  const IconComponent = useCallback(
    () => (
      <InputAdornment position="end">
        {!disabled && <Toggle className={classes.toggleIcon} />}
      </InputAdornment>
    ),
    [],
  )

  return (
    <Select
      displayEmpty
      fullWidth
      IconComponent={IconComponent}
      disabled={disabled}
      open={false}
      renderValue={() => (
        <Text noWrap variant="body2">
          {member ? Utils.getPersonString(member) : plainSelectEmptyLabel}
        </Text>
      )}
      value=""
    />
  )
}

type PlainTextInputProps = PuiTextFieldProps & {
  field: Field
  member?: User
  onClear?: () => void
}

const PlainTextInput = ({
  field,
  member,
  disabled,
  onClear,
  ...rest
}: PlainTextInputProps) => {
  const classes = usePlainTextInputStyles()

  const handleClear = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    onClear?.()
  }

  return (
    <PuiTextField
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              className={classNames(
                classes.iconButton,
                !member && classes.iconButtonHidden,
              )}
              disabled={disabled}
              size="large"
              onClick={onClear ? handleClear : undefined}
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      disabled={disabled}
      field={{
        ...field,
        value: Utils.getPersonString(member),
        set: R.T, // override set since this component is not supposed to change field value, we have popper for that
      }}
      {...rest}
    />
  )
}

export enum UserSelectFilterScope {
  MainStaff = 'MAIN_STAFF',
  Staff = 'STAFF',
  Doctors = 'DOCTORS',
  Techs = 'TECHS',
  Assistants = 'ASSISTANTS',
  Clients = 'CLIENTS',
  Boarders = 'BOARDERS',
  Groomers = 'GROOMERS',
}

export const UserSelectConstantFilterMap: Partial<
  Record<any, UserSelectFilterScope>
> = {
  [RoleName.Veterinarian]: UserSelectFilterScope.Doctors,
  [RoleName.VetTech]: UserSelectFilterScope.Techs,
  [RoleName.VetAssistant]: UserSelectFilterScope.Assistants,
  [RoleName.Boarder]: UserSelectFilterScope.Boarders,
  [RoleName.Groomer]: UserSelectFilterScope.Groomers,
  [ResponsibilityName.Veterinarian]: UserSelectFilterScope.Doctors,
  [ResponsibilityName.Staff]: UserSelectFilterScope.Staff,
}

export const useGetRoleIdsList = (filterScope?: UserSelectFilterScope) => {
  const isRemoteUsersEnabled = useSelector(
    getFeatureToggle(FeatureToggle.REMOTE_MEMBERS),
  )
  const mainStaffRolesIds = useSelector(
    isRemoteUsersEnabled ? getMainStaffRoleIdsList : getStaffRoleIdsList,
  )
  const staffRolesIds = useSelector(getStaffRoleIdsList)
  const mainStaffRoles = useMainStaffRoles()

  const doctorRoleIds = Utils.findConstantIdsByName(
    RoleName.Veterinarian,
    mainStaffRoles,
  )
  const doctorRoleId2 = Utils.findConstantIdByName(
    RoleName.Veterinarian2,
    mainStaffRoles,
  )
  const techRoleIds = Utils.findConstantIdsByName(
    RoleName.VetTech,
    mainStaffRoles,
  )
  const assistantRoleIds = Utils.findConstantIdsByName(
    RoleName.VetAssistant,
    mainStaffRoles,
  )
  const boarderRoleId = Utils.findConstantIdByName(
    RoleName.Boarder,
    mainStaffRoles,
  )
  const groomerRoleId = Utils.findConstantIdByName(
    RoleName.Groomer,
    mainStaffRoles,
  )
  const clientRolesIds = useSelector(getClientRoleIdsList)

  if (!filterScope) {
    return mainStaffRolesIds
  }

  const roleIdsMap = {
    [UserSelectFilterScope.Doctors]: [...doctorRoleIds, doctorRoleId2],
    [UserSelectFilterScope.Techs]: techRoleIds,
    [UserSelectFilterScope.Assistants]: assistantRoleIds,
    [UserSelectFilterScope.Clients]: clientRolesIds,
    [UserSelectFilterScope.Staff]: staffRolesIds,
    [UserSelectFilterScope.MainStaff]: mainStaffRolesIds,
    [UserSelectFilterScope.Boarders]: [boarderRoleId],
    [UserSelectFilterScope.Groomers]: [groomerRoleId],
  }

  return roleIdsMap[filterScope] || roleIdsMap[UserSelectFilterScope.MainStaff]
}

export interface UserSelectAutocompleteProps
  extends Omit<
    AutocompleteProps<any, any, any, any>,
    'options' | 'renderInput' | 'onChange'
  > {
  displayEmpty?: boolean
  displayPetParentOption?: boolean
  emptyLabel?: string
  filterScope?: UserSelectFilterScope
  includeInactive?: boolean
  label?: string
  margin?: PuiTextFieldProps['margin']
  onChange: (member: User) => void
  onClose?: () => void
  petParent?: User
  preload?: boolean
  teamFilter?: TeamFilter
}

export const UserSelectAutocomplete = ({
  multiple,
  margin,
  open = true,
  onClose: onCloseProp,
  onChange,
  classes: classesProp,
  value: valueProp,
  preload,
  includeInactive = false,
  teamFilter = TeamFilter.MEMBER,
  displayEmpty = false,
  label,
  emptyLabel,
  displayPetParentOption = false,
  petParent,
  filterScope,
  ...rest
}: UserSelectAutocompleteProps) => {
  const classes = useAutocompleteStyles({ classes: classesProp })
  const dispatch = useDispatch()
  const roleIdsList = useGetRoleIdsList(filterScope)
  const isLoading = useSelector(getSecretSearchIsReceiving)
  const searchResults: SuggestionResult[] = useSelector(getSuggestionResults)
  const usersMap = useSelector(getUsersMap)
  const totalCount = useSelector(getSuggestedTotalCount)
  const { t } = useTranslation(['Common', 'Search'])
  const userSelectAutocompleteLabel = label || t<string>('Search:TEAM_MEMBERS')
  const userSelectAutocompleteEmptyLabel =
    emptyLabel || t<string>('Common:UNASSIGNED')

  const [rawInputValue, setRawInputValue] = useState('')
  const [inputValue] = useDebounce(rawInputValue, Defaults.DEBOUNCE_ACTION_TIME)

  const members = searchResults.map(({ id }) => usersMap[id]).filter(Boolean)

  const membersWithPetParent = displayPetParentOption
    ? [getPetParentOption(petParent), ...members]
    : members

  const memberOptions = displayEmpty
    ? [
        getEmptyOption(userSelectAutocompleteEmptyLabel),
        ...membersWithPetParent,
      ]
    : membersWithPetParent

  const dispatchFetchSearch = (searchTerm: string) => {
    dispatch(
      fetchSuggestionResults({
        searchContext: SecretSearchContext.PERSONS,
        searchTerm,
        teamFilter,
        roleIds: roleIdsList.join(','),
        includeInactive,
        shorten: true,
      }),
    )
  }

  useEffect(
    () => () => {
      if (!preload) {
        dispatch(clearSuggestionResults())
      }
    },
    [],
  )

  useEffectExceptOnMount(() => {
    if (open) {
      dispatchFetchSearch(inputValue)
    }
  }, [inputValue])

  useEffect(() => {
    if (open && !preload) {
      dispatchFetchSearch(rawInputValue)
    }
  }, [open, preload])

  const findMember = (id: string) =>
    memberOptions.find((member) => member.id === id) || null

  const value = multiple ? valueProp.map(findMember) : findMember(valueProp)

  const OptionComponent = multiple ? MultipleOption : Option

  const handleClearOnClose = () => {
    setRawInputValue('')
    if (onCloseProp) {
      onCloseProp()
    }
  }

  const onClose = (
    event: React.SyntheticEvent,
    reason: AutocompleteCloseReason,
  ) => {
    const isLoadingAreaClick = reason === 'blur' && isLoading
    const isSearchBlur = reason === 'blur' && multiple
    const isMultipleOptionClick =
      multiple && (reason === 'selectOption' || reason === 'removeOption')
    if (
      isSearchBlur ||
      isLoadingAreaClick ||
      isMultipleOptionClick ||
      reason === 'toggleInput'
    ) {
      return
    }

    handleClearOnClose()
  }

  const setListboxScrollOnFetched = useCloseAfterCreation(
    (element, scrollTop) => {
      element.scrollTo(0, scrollTop)
    },
    getSecretSearchIsReceiving,
  )

  const onListboxScroll = (event: React.SyntheticEvent<HTMLUListElement>) => {
    const listBox = event.currentTarget
    if (listBox.scrollTop + listBox.offsetHeight > listBox.scrollHeight - 30) {
      if (totalCount > searchResults.length && !isLoading) {
        setListboxScrollOnFetched(listBox, listBox.scrollTop)
        dispatch(
          fetchMoreSuggestionResults({
            searchContext: SecretSearchContext.PERSONS,
            searchTerm: rawInputValue,
            roleIds: roleIdsList.join(','),
            includeInactive,
            teamFilter,
            from: searchResults.length,
            to: searchResults.length + Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
            shorten: true,
          }),
        )
      }
    }
  }

  return (
    <Autocomplete
      autoComplete
      autoHighlight
      disableClearable
      disablePortal
      includeInputInList
      open
      ListboxProps={{
        onScroll: onListboxScroll,
      }}
      classes={{
        option: classNames({
          [classes.multipleOption]: multiple,
        }),
        listbox: classes.listbox,
        popperDisablePortal: classes.popperDisablePortal,
        root: classes.autocompleteRoot,
        paper: classes.paper,
        popper: classes.popper,
      }}
      forcePopupIcon={false}
      getOptionLabel={(option) => Utils.getPersonString(option)}
      inputValue={rawInputValue}
      isOptionEqualToValue={(option, currentValue) =>
        R.is(Object, currentValue)
          ? option.id === currentValue.id
          : option.id === currentValue
      }
      loading={isLoading}
      multiple={multiple}
      options={memberOptions}
      renderInput={(params) => (
        <PuiTextField
          {...params}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          label={userSelectAutocompleteLabel}
          margin={margin}
          onChange={Utils.handleFormTextInput(setRawInputValue)}
        />
      )}
      renderOption={(props, member, { selected }) =>
        member?.id === PET_PARENT_OPTION_ID ? (
          <PetParentOption {...props} member={member} />
        ) : (
          <OptionComponent {...props} member={member} selected={selected} />
        )
      }
      renderTags={() => null}
      value={value}
      onChange={(event, memberValue) => {
        onChange(
          multiple
            ? // filter lets avoid set null values if options is dinamically changed
              memberValue.filter(Boolean)
            : memberValue,
        )
      }}
      onClose={onClose}
      {...rest}
    />
  )
}

export interface UserSelectPopperProps extends UserSelectAutocompleteProps {
  PopperProps?: Partial<PuiPopperProps>
  anchorEl: PuiPopperProps['anchorEl']
  onClose: () => void
  open: boolean
}

export const UserSelectPopper = ({
  anchorEl,
  open,
  onClose,
  PopperProps = {},
  ...rest
}: UserSelectPopperProps) => {
  const classes = usePopperStyles()

  const id = open ? 'user-select' : undefined

  return (
    <PuiPopper
      hideCloseButton
      anchorEl={anchorEl}
      classes={{
        popper: classes.popper,
        paper: classes.paper,
      }}
      id={id}
      modifiers={[
        {
          name: 'flip',
          enabled: false,
        },
      ]}
      open={open}
      placement="bottom-start"
      onClose={onClose}
      {...PopperProps}
    >
      <UserSelectAutocomplete
        classes={{
          popper: classes.popper,
        }}
        open={open}
        onClose={onClose}
        {...rest}
      />
    </PuiPopper>
  )
}

export interface UserSelectProps
  extends Pick<AutocompleteProps<any, any, any, any>, 'classes'> {
  PopperProps?: Partial<PuiPopperProps>
  disabled?: boolean
  displayEmpty?: boolean
  displayPetParentOption?: boolean
  emptyLabel?: string
  field?: Field
  filterScope?: UserSelectFilterScope
  includeInactive?: boolean
  label?: string
  petParent?: User
  plain?: boolean
  plainInput?: boolean
  plainSelect?: boolean
  preload?: boolean
  preloadUser?: User | null | undefined
  readOnly?: boolean
  teamFilter?: TeamFilter
}

const UserSelect = ({
  field,
  label,
  plain,
  plainSelect,
  plainInput,
  emptyLabel,
  preload,
  preloadUser,
  includeInactive = false,
  teamFilter = TeamFilter.MEMBER,
  displayPetParentOption = false,
  petParent,
  PopperProps,
  disabled,
  readOnly,
  filterScope = UserSelectFilterScope.MainStaff,
  ...rest
}: UserSelectProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))
  const user = useSelector(getUser(field?.value))
  const selectedMember =
    field?.value === PET_PARENT_OPTION_ID
      ? getPetParentOption(petParent)
      : preload && preloadUser
        ? preloadUser
        : user
  const roleIdsList = useGetRoleIdsList(filterScope)
  const { t } = useTranslation('Common')

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>()

  const open = Boolean(anchorEl)
  const shouldShowBorder = !plain && !plainSelect && !plainInput

  const [openDialog, closeDialog] = useDialog(DialogNames.ASSIGN_TEAM_MEMBER)

  useEffect(() => {
    if (preload) {
      dispatch(
        fetchSuggestionResults({
          searchContext: SecretSearchContext.PERSONS,
          searchTerm: preloadUser
            ? `${preloadUser?.firstName} ${preloadUser?.lastName}`
            : '',
          teamFilter,
          roleIds: roleIdsList.join(','),
          includeInactive,
        }),
      )
    }
  }, [])

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isMobile) {
      openDialog({
        title: t('Common:SELECT_TEAM_MEMBER'),
        displayPetParentOption,
        emptyLabel,
        filterScope,
        includeInactive,
        label: t('Common:SEARCH_ACTION'),
        petParent,
        preload,
        value: field?.value,
        teamFilter,
        onChange: (member: User) => {
          field?.setValue(member.id)
          closeDialog()
        },
        ...rest,
      })
    } else {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <Grid
      container
      item
      alignItems="center"
      className={classNames(classes.root, {
        [classes.rootEnabled]: !disabled && !readOnly,
        [classes.rootBordered]: shouldShowBorder,
        [classes.invalidBorder]: shouldShowBorder && field && !field.valid,
      })}
      onClick={disabled || readOnly ? undefined : handleClick}
    >
      {plain ? (
        <PlainOption
          disabled={disabled}
          emptyLabel={emptyLabel}
          member={selectedMember}
        />
      ) : plainSelect ? (
        <PlainSelect disabled={disabled} member={selectedMember} />
      ) : plainInput ? (
        field && (
          <PlainTextInput
            disabled={disabled}
            field={field}
            label={label}
            member={selectedMember}
            onClear={disabled ? undefined : () => field.setValue(undefined)}
            {...rest}
          />
        )
      ) : (
        <>
          <Grid
            container
            item
            xs
            alignItems="center"
            className={classes.textContainer}
            wrap="nowrap"
          >
            {selectedMember ? (
              <Option assigned member={selectedMember} />
            ) : (
              <Text
                className={classNames({
                  [classes.invalidColor]: field && !field.valid,
                })}
                py={1}
                variant="lowAccent"
              >
                {label}
              </Text>
            )}
          </Grid>
          {!readOnly &&
            (open ? (
              <ExpandLessIcon className={classes.expandIcon} />
            ) : (
              <ExpandMoreIcon className={classes.expandIcon} />
            ))}
        </>
      )}
      {!isMobile && (
        <UserSelectPopper
          PopperProps={PopperProps}
          anchorEl={anchorEl}
          displayPetParentOption={displayPetParentOption}
          emptyLabel={emptyLabel}
          filterScope={filterScope}
          includeInactive={includeInactive}
          open={open}
          petParent={petParent}
          preload={preload}
          teamFilter={teamFilter}
          value={field?.value}
          onChange={(member) => member && field?.setValue(member.id)}
          onClose={handleClose}
          {...rest}
        />
      )}
    </Grid>
  )
}

export default UserSelect
