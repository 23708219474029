import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormControl } from '@mui/material'
import { Field, PuiCheckbox } from '@pbt/pbt-ui-components'

import { AlertBanner } from '~/components/elements/AlertBanner/AlertBanner'

import CancellationPolicyText from '../CancellationPolicyText'

export interface AppointmentCancellationWaiveFeeAlertProps {
  field: Field
  onChange: (checked: boolean) => void
}

const AppointmentCancellationWaiveFeeAlert = ({
  field,
  onChange,
}: AppointmentCancellationWaiveFeeAlertProps) => {
  const { t } = useTranslation(['Common'])

  const onChangeInternal = (event: React.ChangeEvent<HTMLInputElement>) => {
    field.setValue(event.target.checked)
    onChange(event.target.checked)
  }

  return (
    <FormControl fullWidth margin="normal">
      <AlertBanner
        ContainerProps={{ mt: 1, p: 0.5 }}
        content={
          <>
            <CancellationPolicyText isCancelDialog />
            <PuiCheckbox
              checked={field.value}
              label={t('Common:WAIVE_LATE_CANCELLATION_FEE')}
              onChange={onChangeInternal}
            />
          </>
        }
        variant="warning"
      />
    </FormControl>
  )
}

export default AppointmentCancellationWaiveFeeAlert
