import * as R from 'ramda'
import { AnyAction } from 'redux'
import { createSelector } from 'reselect'
import { ApiError, Defaults } from '@pbt/pbt-ui-components'

import { InventoryOrder, TableFilter } from '~/types'
import { mergeArraysAtIndex, secondLevelMerge } from '~/utils'
import { getErrorMessage } from '~/utils/errors'

import type { RootState } from '../index'

export const FETCH_INVENTORY_ORDERS = 'inventoryOrders/FETCH_INVENTORY_ORDERS'
export const FETCH_INVENTORY_ORDERS_SUCCESS =
  'inventoryOrders/FETCH_INVENTORY_ORDERS_SUCCESS'
export const FETCH_INVENTORY_ORDERS_FAILURE =
  'inventoryOrders/FETCH_INVENTORY_ORDERS_FAILURE'

export const FETCH_MORE_INVENTORY_ORDERS =
  'inventoryOrders/FETCH_MORE_INVENTORY_ORDERS'
export const FETCH_MORE_INVENTORY_ORDERS_SUCCESS =
  'inventoryOrders/FETCH_MORE_INVENTORY_ORDERS_SUCCESS'
export const FETCH_MORE_INVENTORY_ORDERS_FAILURE =
  'inventoryOrders/FETCH_MORE_INVENTORY_ORDERS_FAILURE'

export const UPDATE_INVENTORY_ORDERS = 'inventoryOrders/UPDATE_INVENTORY_ORDERS'

export const SET_FILTERS = 'inventoryOrders/SET_FILTERS'
export const SET_SORTINGS = 'inventoryOrders/SET_SORTINGS'

export const fetchInventoryOrders = (from: number, to: number) => ({
  type: FETCH_INVENTORY_ORDERS,
  from,
  to,
})

export const fetchInventoryOrdersSuccess = (
  list: string[],
  totalCount: number,
) => ({
  type: FETCH_INVENTORY_ORDERS_SUCCESS,
  list,
  totalCount,
})

export const fetchInventoryOrdersFailure = (error: ApiError) => ({
  type: FETCH_INVENTORY_ORDERS_FAILURE,
  error,
})

export const fetchMoreInventoryOrders = (from: number, to: number) => ({
  type: FETCH_MORE_INVENTORY_ORDERS,
  from,
  to,
})
export const fetchMoreInventoryOrdersSuccess = (
  list: string[],
  totalCount: number,
  from: number,
) => ({
  type: FETCH_MORE_INVENTORY_ORDERS_SUCCESS,
  list,
  totalCount,
  from,
})
export const fetchMoreInventoryOrdersFailure = (error: ApiError) => ({
  type: FETCH_MORE_INVENTORY_ORDERS_FAILURE,
  error,
})

export const setInventoryOrderFilters = (
  filters: Record<string, TableFilter>,
) => ({
  type: SET_FILTERS,
  filters,
})

export const setInventoryOrderSortings = (
  sortings: Record<string, boolean>,
) => ({
  type: SET_SORTINGS,
  sortings,
})

export const updateInventoryOrders = (
  orders: Record<string, InventoryOrder>,
) => ({
  type: UPDATE_INVENTORY_ORDERS,
  orders,
})

export type InventoryOrdersState = {
  error: string | null
  filters: Record<string, TableFilter>
  isDeleting: boolean
  isFetching: boolean
  isFetchingList: boolean
  isLoading: boolean
  isUpdating: boolean
  list: string[]
  map: Record<string, InventoryOrder>
  sortings: Record<string, boolean>
  totalCount: number
}

const INITIAL_STATE: InventoryOrdersState = {
  list: [],
  map: {},
  filters: {},
  sortings: {},
  totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
  isLoading: false,
  isFetching: false,
  isFetchingList: false,
  isUpdating: false,
  isDeleting: false,
  error: null,
}

export const inventoryOrdersReducer = (
  state: InventoryOrdersState = INITIAL_STATE,
  action: AnyAction,
): InventoryOrdersState => {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      }
    }
    case SET_SORTINGS: {
      return {
        ...state,
        sortings: action.sortings,
      }
    }
    case UPDATE_INVENTORY_ORDERS:
      return {
        ...state,
        map: secondLevelMerge(state.map, action.orders),
      }
    case FETCH_INVENTORY_ORDERS:
      return {
        ...state,
        list: [],
        isLoading: true,
        isFetching: true,
        isFetchingList: true,
        totalCount: Defaults.INFINITE_LIST_BATCH_LOAD_COUNT,
        error: null,
      }
    case FETCH_INVENTORY_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        list: action.list,
        totalCount: action.totalCount,
      }
    case FETCH_INVENTORY_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isFetching: false,
        isFetchingList: false,
        error: getErrorMessage(action.error),
      }
    case FETCH_MORE_INVENTORY_ORDERS:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FETCH_MORE_INVENTORY_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        list: mergeArraysAtIndex(state.list, action.list, action.from),
        totalCount: action.totalCount,
      }
    case FETCH_MORE_INVENTORY_ORDERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: getErrorMessage(action.error),
      }
    default:
      return state
  }
}

export const getInventoryOrders = (state: RootState): InventoryOrdersState =>
  state.inventoryOrders
export const getInventoryOrdersIsLoading = (state: RootState) =>
  getInventoryOrders(state).isLoading
export const getInventoryOrdersIsFetching = (state: RootState) =>
  getInventoryOrders(state).isFetching
export const getInventoryOrdersIsFetchingList = (state: RootState) =>
  getInventoryOrders(state).isFetchingList
export const getInventoryOrdersTotalCount = (state: RootState) =>
  getInventoryOrders(state).totalCount
export const getInventoryOrdersFilters = (state: RootState) =>
  getInventoryOrders(state).filters
export const getInventoryOrdersSortings = (state: RootState) =>
  getInventoryOrders(state).sortings
export const getInventoryOrdersList = (state: RootState) =>
  getInventoryOrders(state).list
export const getInventoryOrdersMap = (state: RootState) =>
  getInventoryOrders(state).map
export const getMultipleInventoryOrders = (ids: string[]) =>
  createSelector(getInventoryOrdersMap, (map) => R.props(ids, map))
