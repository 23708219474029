import React from 'react'
import { useTranslation } from 'react-i18next'
import { BasePuiDialogProps, Nil } from '@pbt/pbt-ui-components'

import MultiAttachmentHistoryDialog from '../attachment/MultiAttachmentHistoryDialog'

interface AttachmentHistoryDialogProps extends BasePuiDialogProps {
  clientId: string | Nil
  patientId: string | Nil
}

const AddPMRDialog = ({
  open,
  onClose,
  clientId,
  patientId,
}: AttachmentHistoryDialogProps) => {
  const { t } = useTranslation('Common')

  return (
    <MultiAttachmentHistoryDialog
      previousMedicalRecord
      clientId={clientId}
      open={open}
      patientId={patientId}
      title={t('Common:ADD_PREVIOUS_MEDICAL_RECORD')}
      onClose={onClose}
    />
  )
}

export default AddPMRDialog
