import React, { SetStateAction } from 'react'
import { Grid, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Field } from '@pbt/pbt-ui-components'

import { GroupedOption } from '~/components/elements/OutlinedSelect/OutlinedSelect'
import { isOtherMissingPaymentMethodReason } from '~/constants/cardPaymentMethodOrMissingReasonTypes'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'

import { ChewyCardPaymentMethodOnlySelect } from './ChewyCardPaymentMethodOnlySelect'
import OtherMissingPaymentMethodReasonTextField from './OtherMissingPaymentMethodReasonTextField'

const StyledSelectContainerStack = styled(Stack)`
  align-items: flex-start;

  & .MuiFormControl-root {
    max-width: auto;
    width: 100%;
  }

  & .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

interface ChewyCardPaymentMethodDropdownProps {
  cardOnFileOrPaymentMethodMissingReasonField: Field
  cardPaymentMethodsAndMissingPaymentMethodReasons: GroupedOption<
    '' | CardPaymentMethodOrMissingReason
  >[]
  handleChewyCardPaymentMethodOnlySelectChange: (
    value: SetStateAction<CardPaymentMethodOrMissingReason | '' | null>,
  ) => void
  otherReasonField: Field
  paymentMethodOrMissingReasonValue: CardPaymentMethodOrMissingReason | ''
}

const ChewyCardPaymentMethodDropdown = ({
  cardPaymentMethodsAndMissingPaymentMethodReasons,
  cardOnFileOrPaymentMethodMissingReasonField,
  handleChewyCardPaymentMethodOnlySelectChange,
  otherReasonField,
  paymentMethodOrMissingReasonValue,
}: ChewyCardPaymentMethodDropdownProps) => {
  const showOtherReasonField =
    isOtherMissingPaymentMethodReason(
      cardOnFileOrPaymentMethodMissingReasonField?.value?.name,
    ) ||
    isOtherMissingPaymentMethodReason(
      cardOnFileOrPaymentMethodMissingReasonField?.initialValue?.name,
    )

  const isOtherReasonSelected = isOtherMissingPaymentMethodReason(
    cardOnFileOrPaymentMethodMissingReasonField?.value?.name,
  )

  return (
    <StyledSelectContainerStack direction="column">
      <Grid container>
        <Grid item xs={12}>
          <ChewyCardPaymentMethodOnlySelect
            borderlessWhenDisabled
            groupedOptions={cardPaymentMethodsAndMissingPaymentMethodReasons}
            value={paymentMethodOrMissingReasonValue}
            onChange={handleChewyCardPaymentMethodOnlySelectChange}
          />
        </Grid>
        {showOtherReasonField && (
          <Grid
            item
            mt={2}
            px={1}
            sx={{
              border: (theme) => `1px solid ${theme.colors.grayGray3}`,
              backgroundColor: (theme) => theme.colors.grayWhite,
            }}
            xs={12}
          >
            <OtherMissingPaymentMethodReasonTextField
              otherReasonField={otherReasonField}
              shouldAutoFocus={isOtherReasonSelected}
            />
          </Grid>
        )}
      </Grid>
    </StyledSelectContainerStack>
  )
}

export default ChewyCardPaymentMethodDropdown
