import { useProcedureByInternalCodeQuery } from '~/api/graphql/generated/types'
import {
  getPenaltyFeeByOriginalCode,
  MISCELLANEOUS_PROCEDURE_INTERNAL_CODE,
  MiscellanousProcedureOriginalCodes,
} from '~/constants/procedures'

export const useGetNoShowCancellationPenaltyFee = () => {
  const {
    data,
    loading: isPenaltyFeeQueryLoading,
    error: penaltyFeeQueryError,
  } = useProcedureByInternalCodeQuery({
    variables: { internalCode: MISCELLANEOUS_PROCEDURE_INTERNAL_CODE },
  })

  if (penaltyFeeQueryError) {
    return { penaltyFee: null, isPenaltyFeeQueryLoading, penaltyFeeQueryError }
  }

  const prices = data?.procedureByInternalCode?.prices?.data
  const lateCancellationFee = getPenaltyFeeByOriginalCode(
    prices,
    MiscellanousProcedureOriginalCodes.LATE_CANCELLATION_FEE,
  )
  const missedAppointmentFee = getPenaltyFeeByOriginalCode(
    prices,
    MiscellanousProcedureOriginalCodes.MISSED_APPOINTMENT_FEE,
  )

  const penaltyFee =
    lateCancellationFee && missedAppointmentFee
      ? Math.max(lateCancellationFee, missedAppointmentFee)
      : null

  return { penaltyFee, isPenaltyFeeQueryLoading, penaltyFeeQueryError }
}
