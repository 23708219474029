import React from 'react'
import { RadioGroup } from '@mui/material'

import { PrescriptionWorkflowType } from '~/constants/PrescriptionWorkflow'
import { Prescription } from '~/types'
import { getPrescriptionType, getWorkflowType } from '~/utils/prescription'

import ChewyActiveRxWorkflow from './ChewyActiveRxWorkflow'
import ChewyReactiveRxWorkflow from './ChewyReactiveRxWorkflow'
import OrderWorkflow from './OrderWorkflow'
import OutsidePharmacyWorkflow from './OutsidePharmacyWorkflow'
import PrescribeWorkflow from './PrescribeWorkflow'

export interface WorkflowSectionProps {
  activeWorkflow?: PrescriptionWorkflowType
  areChargesPostedAndEditable?: boolean
  clientId: string
  handleSetActiveWorkflow: (
    workflow: PrescriptionWorkflowType | undefined,
  ) => void
  isChewyReactiveRx?: boolean
  isCustomCompound?: boolean
  onChange: (value: PrescriptionWorkflowType) => void
  prescription: Prescription
}

const PrescriptionWorkflowComponent: Record<
  PrescriptionWorkflowType,
  React.JSXElementConstructor<any>
> = {
  [PrescriptionWorkflowType.PRESCRIBE]: PrescribeWorkflow,
  [PrescriptionWorkflowType.ORDER]: OrderWorkflow,
  [PrescriptionWorkflowType.OUTSIDE_PHARMACY]: OutsidePharmacyWorkflow,
  [PrescriptionWorkflowType.CHEWY_REACTIVE_RX]: ChewyReactiveRxWorkflow,
  [PrescriptionWorkflowType.CHEWY_ACTIVE_RX]: ChewyActiveRxWorkflow,
}

const WorkflowSection = ({
  activeWorkflow,
  areChargesPostedAndEditable,
  clientId,
  isChewyReactiveRx,
  isCustomCompound,
  onChange,
  handleSetActiveWorkflow,
  prescription,
}: WorkflowSectionProps) => {
  const { isChewyActiveRx } = getPrescriptionType(
    prescription.prescriptionType,
    prescription.origin,
  )

  const isEdit = prescription.id
  // RHAP-1710 - Until this ticket is done, we will disable switch workflow temporarily
  const disableOtherWorkflowsForCreatedChewyActiveRx = isEdit && isChewyActiveRx

  return (
    <RadioGroup
      row
      aria-label="workflow-section"
      name="workflow-section"
      value={activeWorkflow || ''}
      onChange={(_, radioValue) =>
        onChange(radioValue as PrescriptionWorkflowType)
      }
    >
      {Object.values(PrescriptionWorkflowType).map((workflow) => {
        const Component = PrescriptionWorkflowComponent[workflow]

        const {
          isChewyReactiveRx: isChewyReactiveRxItem,
          isChewyActiveRx: isChewyActiveRxItem,
          isOutsidePharmacy: isOutsidePharmacyItem,
        } = getWorkflowType(workflow)

        const isCustomCompoundSupportedWorkflowItem =
          isChewyActiveRxItem || isChewyReactiveRxItem || isOutsidePharmacyItem

        const fromOrderPrescriptionToOutsideChewy =
          (activeWorkflow === PrescriptionWorkflowType.PRESCRIBE ||
            activeWorkflow === PrescriptionWorkflowType.ORDER) &&
          (workflow === PrescriptionWorkflowType.OUTSIDE_PHARMACY ||
            workflow === PrescriptionWorkflowType.CHEWY_ACTIVE_RX)

        const fromOutsideChewyToOrderPrescription =
          (activeWorkflow === PrescriptionWorkflowType.OUTSIDE_PHARMACY ||
            activeWorkflow === PrescriptionWorkflowType.CHEWY_ACTIVE_RX) &&
          (workflow === PrescriptionWorkflowType.PRESCRIBE ||
            workflow === PrescriptionWorkflowType.ORDER)

        const disabled = Boolean(
          disableOtherWorkflowsForCreatedChewyActiveRx ||
            (!isCustomCompoundSupportedWorkflowItem &&
              (!prescription.variation || isCustomCompound)) ||
            (isChewyReactiveRx && !isChewyReactiveRxItem) ||
            (!isChewyReactiveRx && isChewyReactiveRxItem) ||
            (areChargesPostedAndEditable &&
              (fromOrderPrescriptionToOutsideChewy ||
                fromOutsideChewyToOrderPrescription)),
        )

        return (
          <Component
            activeWorkflow={activeWorkflow}
            clientId={clientId}
            disabled={disabled}
            handleSetActiveWorkflow={handleSetActiveWorkflow}
            isChewyReactiveRx={isChewyReactiveRx}
            isEdit={isEdit}
            key={workflow}
            showDisabledTooltip={!disableOtherWorkflowsForCreatedChewyActiveRx}
          />
        )
      })}
    </RadioGroup>
  )
}

export default WorkflowSection
