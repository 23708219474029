import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  ButtonWithLoader,
  Nil,
  PuiDialog,
  PuiDialogProps,
  Text,
} from '@pbt/pbt-ui-components'

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      width: 650,
      maxWidth: 650,
      [theme.breakpoints.down('md')]: {
        margin: '48px auto !important',
      },
      [theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
      },
    },
  }),
  { name: 'MultiValidationErrorDialog' },
)

export interface MultiValidationErrorDialogProps extends PuiDialogProps {
  errors: string[] | Nil
  onBack: () => void
  onProceed: () => void
  proceedButtonActionName?: string
}

const MultiValidationErrorDialog = ({
  open,
  onBack,
  onClose,
  onProceed,
  errors,
  proceedButtonActionName,
  title,
}: MultiValidationErrorDialogProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const validationErrorProceedButtonActionName =
    proceedButtonActionName || t('Common:SCHEDULE_ACTION')

  const onBackOrClose = () => {
    onBack()
    if (onClose) {
      onClose()
    }
  }

  const onProceedButton = () => {
    onProceed()
    if (onClose) {
      onClose()
    }
  }

  return (
    <PuiDialog
      actions={
        <>
          <ButtonWithLoader onClick={onProceedButton}>
            {t('Common:YES_PROCEED_ACTION_ANYWAY', {
              actionName: validationErrorProceedButtonActionName?.toLowerCase(),
            })}
          </ButtonWithLoader>
          <ButtonWithLoader color="secondary" onClick={onBackOrClose}>
            {t('Common:NO_GO_BACK')}
          </ButtonWithLoader>
        </>
      }
      aria-labelledby="validation-error-dialog"
      classes={{
        paper: classes.paper,
      }}
      open={open}
      title={title}
      onClose={onBackOrClose}
    >
      <Grid container direction="column" p={5}>
        <Grid item>
          {errors?.map((e) => (
            <Text align="left" key={e} variant="body">
              • {e}
            </Text>
          ))}
        </Grid>
      </Grid>
    </PuiDialog>
  )
}

export default MultiValidationErrorDialog
